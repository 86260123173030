import { useEffect } from 'react'
import { Box, Grid, GridItem, Flex, Heading, Image, Text, Spinner } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

// Data
import useServicesData from 'data/useServicesData'

// Images
import imgAdmin from 'img/create_step1.svg'

import NewAdmin from './NewAdmin'
import CurrentAdmins from './CurrentAdmins'

const AdminsTab = () => {
  const { serviceId } = useParams()
  const { addServiceAdmin, servicesAdmins, fetchServiceAdmins, removeServiceAdmin } =
    useServicesData()

  useEffect(() => {
    fetchServiceAdmins({ serviceId })
  }, [fetchServiceAdmins, serviceId])

  return (
    <Box
      width="100%"
      bg="white"
      borderRadius="lg"
      py={8}
      px={12}
      my={6}
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.1);"
    >
      <Flex mb={12} align="center" justify="space-between">
        <Flex justify="flex-start" align="center">
          <Box mr="3">
            <Image src={imgAdmin} />
          </Box>
          <Box>
            <Heading as="h2" size="lg" color="primary.800">
              Admins
            </Heading>
          </Box>
        </Flex>
      </Flex>
      <Grid
        minH="200px"
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={15}
        textAlign="left"
      >
        <GridItem colSpan={2}>
          <Text fontWeight="bold">Admins can access and edit everything about the service.</Text>
          <br />
          <Text>
            Once added, they will be able to access this service by logging in via&nbsp;their&nbsp;
            <i>.gov.sg</i>&nbsp;email.
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <NewAdmin addServiceAdmin={addServiceAdmin} />
          {servicesAdmins && servicesAdmins[serviceId] ? (
            <CurrentAdmins
              admins={servicesAdmins[serviceId]}
              removeServiceAdmin={removeServiceAdmin}
            />
          ) : (
            <Box textAlign="center">
              <Spinner />
            </Box>
          )}
        </GridItem>
      </Grid>
    </Box>
  )
}

export default AdminsTab

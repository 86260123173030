import React from 'react'
import { useToast } from '@chakra-ui/react'

// Components
import PayApi from 'services/PayApi'
import GenericEditableField from './GenericEditableField'

// Services

export default function ServiceInformation({ fetch, fieldKey, fieldName, fieldValue, serviceId }) {
  const toast = useToast()

  async function onSave({ newFieldKey, newFieldValue }) {
    await PayApi.updateService({
      fieldKey: newFieldKey,
      fieldValue: newFieldValue,
      serviceId,
    })
    toast({
      title: 'Success',
      description: 'Payment service updated successfully.',
      status: 'success',
      position: 'top',
      variant: 'subtle',
    })
    await fetch()
  }

  return (
    <GenericEditableField
      fieldKey={fieldKey}
      fieldName={fieldName}
      fieldValue={fieldValue}
      onSave={onSave}
    />
  )
}

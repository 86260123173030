import baseConfig from './baseConfig'

/**
 * @todo move to async/await
 */

/**
 * Get client secret for stripe
 *
 * @param {*} param0
 * @returns
 */
export const getClientSecret = ({ paymentId }) => {
  console.log('[INFO] getClientSecret')
  return baseConfig
    .get(`/payment-link/${paymentId}/get-client-secret`)
    .then((res) => res.data.clientSecret)
}

import React from 'react'
import {
  Badge,
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

// Components
// import AgencyLogo from './components/AgencyLogo'
import step1 from 'img/create_step1.svg'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import ServiceInformation from './components/ServiceInformation'
// import StripeConnect from './components/StripeConnect'

// Data
// import useServiceData from "data/useServiceData";

// Images

export default function SettingsTab({ services, fetch }) {
  const { serviceId } = useParams()
  const service = services[serviceId]

  return !_.isEmpty(service) ? (
    <Box
      bg="white"
      borderRadius="lg"
      py={8}
      px={12}
      my={6}
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.1);"
    >
      <Flex mb={12} align="center" justify="space-between">
        <Flex justify="flex-start" align="center">
          <Box mr="3">
            <Image src={step1} />
          </Box>
          <Box>
            <Heading as="h2" size="lg" color="primary.800">
              Settings
            </Heading>
          </Box>
        </Flex>
      </Flex>

      <VStack mb={6} align="flex-start">
        <Heading as="h2" size="md" color="gray.900">
          Information
        </Heading>
        <Divider mb={4} />
        <ServiceInformation
          serviceId={serviceId}
          fieldKey="title"
          fieldName="Service Title"
          fieldValue={service.title}
          fetch={fetch}
        />
        <ServiceInformation
          serviceId={serviceId}
          fieldKey="information"
          fieldName="Service Information"
          fieldValue={service.information}
          fetch={fetch}
        />
        <ServiceInformation
          serviceId={serviceId}
          fieldKey="agencyName"
          fieldName="Agency Name"
          fieldValue={service.agencyName}
          fetch={fetch}
        />
        <ServiceInformation
          serviceId={serviceId}
          fieldKey="agencyEmail"
          fieldName="Agency Contact Email"
          fieldValue={service.agencyEmail}
          fetch={fetch}
        />
        <ServiceInformation
          serviceId={serviceId}
          fieldKey="agencyAddress"
          fieldName="Agency Address"
          fieldValue={service.agencyAddress}
          fetch={fetch}
        />
        <ServiceInformation
          serviceId={serviceId}
          fieldKey="agencyGstRegNum"
          fieldName="Agency GST Registration Number"
          fieldValue={service.agencyGstRegNum}
          fetch={fetch}
        />
        {/*
        <AgencyLogo service={service} fieldName="Agency Logo" fetch={fetch} />
        */}
      </VStack>
      <Divider mb={4} />
      <HStack w="100%" align="center">
        <Text w="50%" maxW="250px" textAlign="left" color="gray.700" fontWeight="bold">
          Stripe Account Link Status
        </Text>
        <Box w="50%" align="left">
          {service.stripeAccountId ? (
            <Badge variant="solid" colorScheme="green">
              Connected
            </Badge>
          ) : (
            <Box>
              <Badge variant="solid" colorScheme="red" mr={2}>
                Not Connected
              </Badge>
              <Tooltip label="Please contact PaySG support for assistance." py={2} px={4}>
                <QuestionOutlineIcon />
              </Tooltip>
            </Box>
          )}
        </Box>
      </HStack>

      {/*
      <VStack align={"flex-start"}>
        <Heading as="h2" size="md" color="gray.900">
          Connect to Stripe
        </Heading>
        <Divider mb={4} />
        <StripeConnect serviceId={serviceId} fieldName="Stripe Account ID" fieldValue={service.stripeAccountId} />
      </VStack>
      */}
    </Box>
  ) : null
}

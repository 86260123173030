import axios from 'axios'
import baseConfig from './baseConfig'

const { REACT_APP_PAYSG_URL: PAYSG_URL } = process.env

/**
 * @todo move to async/await
 */

export const createService = ({
  serviceTitle,
  serviceInformation,
  serviceAgencyName,
  serviceAgencyEmail,
  serviceAgencyAddress,
  serviceAgencyGstRegNum,
}) => {
  console.log(`[INFO] createService`)
  return baseConfig
    .post(`/services`, {
      service_title: serviceTitle,
      service_information: serviceInformation,
      service_agency_name: serviceAgencyName,
      service_agency_email: serviceAgencyEmail,
      service_agency_address: serviceAgencyAddress,
      service_agency_gst_reg_num: serviceAgencyGstRegNum,
    })
    .then((res) => res.data.service)
}

/**
 * List all services for the user
 *
 * @returns
 */
export const listServices = () => {
  console.log(`[INFO] listServices`)
  return baseConfig.get(`/services`).then((res) => res.data.services)
}

/**
 * Gets a service by id
 *
 * @param {string} serviceId
 * @returns
 */
export const getService = ({ serviceId }) => {
  console.log(`[INFO] getService: ${serviceId}`)
  return baseConfig.get(`/services/${serviceId}`).then((res) => res.data.service)
}

/**
 * Updates a single service field value by service id
 *
 * @param {*} param0
 * @returns
 */
export const updateService = ({ fieldKey, fieldValue, serviceId }) => {
  console.log('[INFO] updateService')
  if (!serviceId) throw Error('serviceId cannot be empty')
  return baseConfig
    .put(`/services/${serviceId}`, {
      [fieldKey]: fieldValue,
    })
    .then((res) => res.data.service)
}

/**
 * Uploads logo and updates service with new logo
 *
 * @param {*} param0
 * @returns
 */
export const updateServiceLogo = ({ formData, serviceId }) => {
  console.log('[INFO] updateServiceLogo')
  if (!formData) throw Error('formData cannot be empty')
  if (!serviceId) throw Error('serviceId cannot be empty')
  // Not using baseConfig because humps corrupts FormData
  return axios
    .post(`${PAYSG_URL}services/${serviceId}/logos`, formData, {
      withCredentials: true,
    })
    .then((res) => res.data)
}

/**
 * Associates a service to a stripe account id
 *
 * @param {*} fieldValue
 * @param {*} serviceId
 * @returns
 */
export const connectToStripe = ({ fieldValue, serviceId }) => {
  console.log('[INFO] connectToStripe')
  if (!fieldValue) throw Error('fieldValue cannot be empty')
  if (!serviceId) throw Error('serviceId cannot be empty')
  return baseConfig
    .post(`/services/${serviceId}/connect-to-stripe`, {
      stripe_account_id: fieldValue,
    })
    .then((res) => res.data)
}

/*
 * Get service admins
 *
 * @param {*} param0
 */
export const listServiceAdmins = ({ serviceId }) => {
  console.log('[INFO] listServiceAdmins')
  return baseConfig.get(`/services/${serviceId}/users`).then((res) => res.data.users)
}

/**
 * Adds a Admin to a service
 *
 * @param {*} param0
 */
export const addAdminToService = ({ serviceId, email }) => {
  console.log('[INFO] addAdminToService')
  return baseConfig
    .post(`/services/${serviceId}/users`, {
      email,
    })
    .then((res) => res.data.service)
}

/**
 * Removes a Admin from a service
 *
 * @param {*} param0
 */
export const removeAdminFromService = ({ serviceId, userId }) => {
  console.log('[INFO] removeAdminFromService')
  return baseConfig.delete(`/services/${serviceId}/users/${userId}`).then((res) => res.data.service)
}

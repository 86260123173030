import { createContext, useContext, useState } from 'react'
import _ from 'lodash'
import Joi from 'joi'
import pLimit from 'p-limit'
import { useParams } from 'react-router-dom'

import { parseCsvFile, countEntries } from '../utils/csv'
import { formatPaymentLink } from '../utils/payment'
import ServicePayApi from '../services/PayApi'

export const CreatePaymentLinksContext = createContext()

export const CreatePaymentLinksProvider = ({ children }) => {
  const { serviceId } = useParams()

  /**
   * States
   */
  const [currentStep, setCurrentStep] = useState(0)
  const [fileName, setFileName] = useState('')

  const [total, setTotal] = useState(0)
  const [parsed, setParsed] = useState([])
  const [processed, setProcessed] = useState([])
  const [errored, setErrored] = useState([])
  const [created, setCreated] = useState([])

  /**
   * Resets the payment link creation process
   */
  const reset = () => {
    setCurrentStep(0)
    setFileName('')
    setTotal(0)
    setParsed([])
    setProcessed([])
    setErrored([])
    setCreated([])
  }

  /**
   * Wizard Steps
   *
   * Note that the order of the steps is important
   */
  const steps = ['Empty', 'ParseCompleted', 'Create']

  /**
   * Progress to the next step
   */
  const nextStep = () => {
    if (currentStep >= steps.length - 1) {
      console.error('nextStep cannot be called at end of wizard.')
      return
    }

    setCurrentStep((step) => step + 1)
  }

  /**
   * Progress to the previous step
   */
  const previousStep = () => {
    if (currentStep <= 0) {
      console.error('previousStep cannot be called at start of wizard.')
      return
    }

    setCurrentStep((step) => step - 1)
  }

  /**
   * Create Payment link
   *
   * @todo consider consolidating the errored and created arrays under processed
   */
  const createPaymentLink = async (currentRow) => {
    const row = { ...currentRow }
    try {
      //  Calls the REST api to create a payment
      const { payment } = await ServicePayApi.createPayment({
        amount: _.round(row.amount * 100, 0),
        description: row.description,
        referenceId: row.reference_id,
        serviceId,
      })

      //  If successful, add to the created array
      setCreated((currentCreated) => [...currentCreated, payment])

      //  Populate csv columns
      row.payment_link = formatPaymentLink(payment.id)
      row.payment_link_id = payment.id
      row.created = 'yes'
      row.errors = 'N.A.'
    } catch (error) {
      //  If unsuccessful, add error messaging from api to errored array
      setErrored((currentErrored) => [...currentErrored, row])

      //  Populate csv columns
      row.created = 'no'
      row.errors = error.response.data.error.message || 'An unknown error occurred.'
    } finally {
      //  Regardless of success, add to the processed array
      setProcessed((currentProcessed) => [...currentProcessed, { ...row }])
    }
  }

  /**
   * Starts the creation
   */
  const startCreate = async () => {
    //  Prevent window from closing until completed
    window.onbeforeunload = () =>
      'Please confirm that you want to exit before payment links are fully created.'

    //  Set concurrency limit
    /**
     * @todo move concurrency limit into config/env var
     */
    const limit = pLimit(10)

    //  Create Async promise pool
    const promisePool = parsed.map((row) => limit(() => createPaymentLink(row)))

    //  Runs the promise pool with concurrency
    await Promise.all(promisePool)

    //  Enables the user to close the window without prompting
    window.onbeforeunload = null
  }

  /**
   * Parsing Payment CSV
   */
  /**
   * validates each create payment row
   *
   * @param {object} processedRow
   * @returns
   */
  const validateCreatePaymentRow = async (processedRow) => {
    //  Joi Validation Schema
    const schema = Joi.object({
      reference_id: Joi.string().required(),
      amount: Joi.number().positive().required(),
      description: Joi.string().empty('').required(),
      errors: Joi.string().empty(''),
    })

    return schema.validateAsync(processedRow, { abortEarly: false })
  }
  /**
   * Processes the array of results
   *
   * @param {array} rows
   */
  const processResults = async (rows = []) => {
    if (Array.isArray(rows)) {
      //  Process each row
      // eslint-disable-next-line no-restricted-syntax
      for (const row of rows) {
        //  consider amount into a Number primitive
        const processedRow = {
          ...row,
          amount: Number(row.amount),
          errors: '',
        }

        try {
          //  Validates each row with Joi
          // eslint-disable-next-line no-await-in-loop
          await validateCreatePaymentRow(processedRow)
        } catch (error) {
          /**
           * @todo: remove the validation hash
           */
          if (window.location.hash !== '#novalidate') {
            processedRow.errors += `${error.message}. `
          }
        }

        //  Set to the parsed error
        setParsed((currentParsed) => [...currentParsed, processedRow])
      }
    } else {
      throw Error('rows is not a valid array')
    }
  }

  /**
   * Parses a CSV File
   *
   * @param {*} file
   */
  const parseFile = async (file) => {
    //  Sets the file name to be displayed on the frontend
    setFileName(file.name)

    //  Counts the total number of rows
    setTotal(await countEntries(file))

    //  Parse the CSV file in chunks
    parseCsvFile(file, processResults)
  }

  return (
    <CreatePaymentLinksContext.Provider
      value={{
        reset,

        //  Steps
        steps,
        currentStep,
        nextStep,
        previousStep,

        //  Parsing the payment links CSV
        fileName,
        parseFile,
        parsed,

        //  Creating a payment link
        startCreate,
        processed,
        errored,
        created,
        total,
      }}
    >
      {children}
    </CreatePaymentLinksContext.Provider>
  )
}

export const useCreatePaymentLinks = () => {
  const context = useContext(CreatePaymentLinksContext)
  if (context === undefined) {
    throw new Error('useCreatePaymentLinks must be used within a CreatePaymentLinksProvider')
  }
  return context
}

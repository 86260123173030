import React, { useState } from 'react'
import _ from 'lodash'
import { Button, useDisclosure } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import PayApi from 'services/PayApi'
import CreateLinkModal from './CreateLinkModal'
import ErrorToast from '../../../ErrorToast'

export default function CreateLinkModalContainer({ service, refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { serviceId: sid } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorState, setErrorState] = useState({})

  const checkForStripeAccount = () => {
    if (process.env.REACT_APP_ENV === 'production' && !(service && service.stripeAccountId)) {
      ErrorToast()
    } else {
      onOpen()
    }
  }

  // Form Methods
  const createLink = async ({ amount, description, referenceId, serviceId = sid }) => {
    if (!sid) throw Error('service id cannot be null')

    setIsSubmitting(true)
    setErrorState({})

    try {
      await PayApi.createPayment({
        amount: _.round(amount * 100, 0),
        description,
        referenceId,
        serviceId,
      })
      refetch()
      onClose()
    } catch (error) {
      if (typeof error?.response?.data?.error?.data === 'object') {
        setErrorState(error?.response?.data?.error?.data)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Button colorScheme="primary" onClick={checkForStripeAccount}>
        Create a New Link
      </Button>

      <CreateLinkModal
        isOpen={isOpen}
        onClose={onClose}
        createLink={createLink}
        isSubmitting={isSubmitting}
        errorState={errorState}
      />
    </>
  )
}

import _ from 'lodash'
import baseConfig from './baseConfig'

/**
 * @todo move to async/await
 */

/**
 * Creates a single payment link
 *
 * @param {*} param0
 * @returns
 */
export const createPayment = ({ amount, description, referenceId, serviceId }) => {
  console.log('[INFO] createPayment')
  return baseConfig
    .post(`/services/${serviceId}/payment-link`, {
      amount,
      description,
      referenceId,
    })
    .then((res) => res.data)
}

/**
 * Gets a single payment link
 *
 * @param {*} param0
 * @returns
 */
export const getPayment = ({ paymentId }) => {
  console.log('[INFO] getPayment')
  return baseConfig.get(`/payment-link/${paymentId}`).then((res) => res.data)
}

/**
 * List all payment links for a service id
 *
 * @param {*} param0
 * @returns
 */
export const listPayments = ({ serviceId }) => {
  console.log('[INFO] listPayments')
  // Sort by decreasing order of timestamp before returning
  return baseConfig.get(`/services/${serviceId}/payment-link`).then((res) => {
    return _.reverse(res.data.payments)
  })
}

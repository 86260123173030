import { Box, Flex, Image, Text } from '@chakra-ui/react'

import paySgGirl from 'img/paySgGirl.svg'

/**
 * This component is displayed when there are no links
 *
 * @returns
 */
const LinksEmpty = () => {
  return (
    <Box bg="white" borderRadius="lg" pt="10px" pb="30px">
      <Flex flexDirection="column" w="100%" justifyContent="center" alignItems="center">
        <Flex>
          <Image h="230px" mb={8} src={paySgGirl} />
        </Flex>
        <Flex maxW="360px">
          <Text fontWeight="semibold">
            Create a new link to generate a customised payment link that you can send to your
            customer to make payment
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default LinksEmpty

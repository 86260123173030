import React from 'react'
import { Box, Image, Container, Text, Link, HStack } from '@chakra-ui/react'

import sgCrest from 'img/sg-crest.svg'

const Masthead = () => {
  return (
    <>
      <Box d="flex" alignItems="center" bg="#eee" w="100%" h="30px">
        <Container maxW="96em">
          <Link href="https://www.gov.sg">
            <HStack spacing="12px">
              <Image src={sgCrest} boxSize="20px" />
              <Text fontSize="sm" color="gray.800">
                A Singapore Government Website
              </Text>
            </HStack>
          </Link>
        </Container>
      </Box>
    </>
  )
}

export default Masthead

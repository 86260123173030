import { createStandaloneToast, Link, Text } from '@chakra-ui/react'
import Theme from 'Theme'

export default function ErrorToast() {
  const toast = createStandaloneToast({ theme: Theme })
  const errorMsg = (
    <Text>
      This payment service does not have a connected stripe account. Please contact PaySG at{' '}
      <Link href="mailto:feedback@pay.gov.sg" color="blue">
        feedback@pay.gov.sg
      </Link>{' '}
      to link your stripe account.
    </Text>
  )
  const id = 'create-link-error'

  if (!toast.isActive(id)) {
    return toast({
      id,
      title: 'Stripe account not linked.',
      description: errorMsg,
      position: 'top',
      status: 'error',
      variant: 'subtle',
      duration: null,
      isClosable: true,
    })
  }
}

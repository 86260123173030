import { Flex, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { useServicesTable } from 'contexts/servicesTable.context'
import { IoArrowDownOutline, IoArrowUpOutline } from 'react-icons/io5'
import Pagination from 'components/Pagination'
import ServiceRow from './components/ServiceRow'

export default function Services() {
  const { table } = useServicesTable()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = table

  const renderSortIcon = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? <IoArrowUpOutline /> : <IoArrowDownOutline />
    }

    return null
  }

  return (
    <>
      <Table size="md" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            // Apply the header row props
            <Tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} bg="neutral.100">
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <Th
                    key={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    p={3}
                  >
                    <Flex alignItems="center">
                      {
                        // Render the header
                        column.render('Header')
                      }
                      {/* Add a sort direction indicator */}
                      <span>{renderSortIcon(column)}</span>
                    </Flex>
                  </Th>
                ))
              }
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return <ServiceRow key={row.id} row={row} />
          })}
        </Tbody>
      </Table>
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageCount={pageCount}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  )
}

import React from 'react'
import {
  Stack,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  Text,
} from '@chakra-ui/react'

import 'styles/components/SidePanel.scss'

import { formatPaymentLink } from 'utils/payment'
import { toDateTime } from 'utils/date'

import LinkPaymentIntentStatus from './LinkPaymentIntentStatus'

export default function LinkDetailsPanel({ link, isOpen, onClose }) {
  const viewLink = () => {
    window.open(formatPaymentLink(link.id))
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay>
        <DrawerContent p="5">
          <DrawerCloseButton mr="5" mt="5" />
          <DrawerHeader>
            <Heading>Link Details</Heading>
            <LinkPaymentIntentStatus paymentStatus={link.paymentStatus} />
          </DrawerHeader>

          <DrawerBody mt="6">
            <Stack spacing="6">
              <Box>
                <Heading size="xs" mb="2">
                  Reference ID
                </Heading>
                <Text>{link.referenceId}</Text>
              </Box>
              <Box>
                <Heading size="xs" mb="2">
                  Payment Link ID
                </Heading>
                <Text>{link.id}</Text>
              </Box>
              <Box>
                <Heading size="xs" mb="2">
                  Amount
                </Heading>
                <Text>${(link.amount / 100).toFixed(2)}</Text>
              </Box>
              <Box>
                <Heading size="xs" mb="2">
                  Product Description
                </Heading>
                <Text>{link.description}</Text>
              </Box>
              <Box>
                <Heading size="xs" mb="2">
                  Created At
                </Heading>
                <Text>{toDateTime(link.createdAt)}</Text>
              </Box>
              <Box>
                <Heading size="xs" mb="2">
                  Paid At
                </Heading>
                <Text>
                  {link.paymentSucceededTimestamp
                    ? toDateTime(link.paymentSucceededTimestamp)
                    : 'N.A.'}
                </Text>
              </Box>
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            {/* <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button> */}
            <Button colorScheme="blue" mr="4" onClick={viewLink}>
              View Payment Link
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

// Components
import FakeForm from './FakeForm'

// Services
import PayApi from '../../services/PayApi'

const FakeFormContainer = () => {
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async ({ amount, description, referenceId }) => {
    // set isLoading to true
    setIsSubmitting(true)

    // Create payment link
    const amountInCents = _.round(amount * 100, 0)
    const response = await PayApi.createPayment({
      amount: amountInCents,
      description,
      referenceId,
    })
    const { payment } = response

    // Redirect to payment link page
    history.push(`/payment/${payment && payment.id}?formsg=true`)
  }
  return <FakeForm isSubmitting={isSubmitting} onSubmit={onSubmit} />
}

export default FakeFormContainer

import { useEffect, useState } from 'react'
import { Box, Flex, IconButton, Input, HStack, Select } from '@chakra-ui/react'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'

const Pagination = ({
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  gotoPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
}) => {
  const [page, setPage] = useState(pageIndex + 1)

  useEffect(() => {
    setPage(pageIndex + 1)
  }, [pageIndex])

  const changeInput = (e) => {
    setPage(e.target.value)
  }

  const changePage = (e) => {
    if (e.code === 'Enter') {
      const newPage = e.target.value ? Number(e.target.value) - 1 : 0
      gotoPage(newPage)
    }
  }

  return (
    <HStack mt={5} justifyContent="space-between">
      <Flex alignItems="center">
        <Select
          w="200px"
          h="45px"
          size="sm"
          mx={2}
          borderRadius={6}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              Show&nbsp;{size}&nbsp;Items
            </option>
          ))}
        </Select>
      </Flex>
      <Flex alignItems="center">
        <IconButton
          variant="ghost"
          colorScheme="primary"
          icon={<IoChevronBackOutline />}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />

        <Box px={5}>
          <strong>
            Page{' '}
            <Input
              p={1}
              mx={1}
              borderRadius={6}
              width="38px"
              size="sm"
              textAlign="center"
              type="number"
              value={page}
              onChange={changeInput}
              onKeyPress={changePage}
            />{' '}
            of {pageOptions.length}
          </strong>
        </Box>

        <IconButton
          variant="ghost"
          colorScheme="primary"
          icon={<IoChevronForwardOutline />}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        />
      </Flex>
    </HStack>
  )
}

export default Pagination

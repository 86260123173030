import { Td, Tr } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { toDateTime } from 'utils/date'

export default function ServiceRow({ row }) {
  const history = useHistory()
  const goToService = (serviceId) => history.push(`/services/${serviceId}`)

  return (
    <Tr
      {...row.getRowProps()}
      fontSize="0.85em"
      _hover={{
        cursor: 'pointer',
        background: '#F1F3F8',
      }}
      onClick={() => goToService(row.original.id)}
    >
      {row.cells.map((cell) => {
        const { column, value } = cell
        switch (column.id) {
          case 'title':
            return (
              <Td key={column.id} px={3} py={4}>
                {value}
              </Td>
            )

          case 'agencyName':
            return (
              <Td key={column.id} px={3} py={4}>
                {value}
              </Td>
            )

          case 'createdAt':
            return (
              <Td key={column.id} px={3} py={4}>
                {toDateTime(value)}
              </Td>
            )

          case 'updatedAt':
            return (
              <Td key={column.id} px={3} py={4}>
                {toDateTime(value)}
              </Td>
            )

          default:
            return (
              <Td key={column.id} px={3} py={4}>
                {cell.render('Cell')}
              </Td>
            )
        }
      })}
    </Tr>
  )
}

import Papa from 'papaparse'
import _ from 'lodash'

/**
 * These utilities support the parsing and creation of csv/json
 *
 * Currently supports only papaparse
 */
export const jsonToCsv = (data, config = {}) => {
  return Papa.unparse(data, config)
}

/**
 * Downloads the csv as a file
 * @todo check across multiple browsers for compatibility
 *
 * @param {string} csv
 */
export const downloadCsv = (csv, filename = 'paysg_download.csv') => {
  const blob = new Blob([csv])

  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    //  Create a hidden link
    const a = window.document.createElement('a')

    //  Create a downloadable blob
    a.href = window.URL.createObjectURL(blob, { type: 'text/csv' })
    a.download = filename

    //  Attach to document and force a click
    document.body.appendChild(a)

    // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    a.click()

    //  Clean up
    document.body.removeChild(a)
  }
}

/**
 * Parses a CSV in chunk
 * Current implementation offloads parsing to a worker to enable reactivity on the frontend
 *
 * @param {File} file
 */
export const parseCsvFile = (
  file, // streaming file from reader
  processResults,
  header = true
) => {
  Papa.parse(file, {
    //  Offload to worker to enable UI reactivity
    worker: true,

    //  Accounts for headers in the csv
    header,

    //  Skip empty lines
    skipEmptyLines: 'greedy',

    //  Ideally use it if we can guarantee no " characters appears.
    fastMode: false,

    //  Maximum chunk size of 10000 bytes
    chunkSize: 10000,

    //  Process the results in chunks
    chunk: (results) => {
      const trimmedData = results.data.map((line) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in line) {
          if (_.isString(line[key])) {
            // eslint-disable-next-line no-param-reassign
            line[key] = line[key].trim()
          }
        }
        return line
      })
      processResults(trimmedData)
    },
  })
}

/**
 * Counts the number of entries in the file.
 * Note that it accounts for the header in the csv
 * Note that it ignores blank rows
 *
 * @param {File} file
 * @returns
 */
export const countEntries = async (
  file,
  hasHeader = true,
  newLineDelimiter = '\n',
  delimiter = ','
) => {
  //  Package as a promise to handle asynchronously
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    //  onload event
    reader.onload = (event) => {
      const csv = event.target.result

      //  Split the csv into rows
      const csvArray = csv.split(newLineDelimiter)

      //  Count the number of columns. Assumes well formed CSV
      const numberOfColumns = csvArray[0].split(delimiter).length

      //  If row length === numberOf Columns, probably all delimiters and therefore empty
      const csvArrayNonEmptyRows = csvArray.filter((row) => row.length > numberOfColumns)

      //  Resolve with number of non-empty rows. Accounts for header
      resolve(csvArrayNonEmptyRows.length - (hasHeader ? 1 : 0))
    }

    //  onerror event: Reject promise
    reader.onerror = reject

    //  Initialise the reading of the File
    reader.readAsText(file)
  })
}

import * as Authentication from './Authentication'
import * as Payments from './Payments'
import * as Services from './Services'
import * as Stripe from './Stripe'

const exports = {
  ...Authentication,
  ...Payments,
  ...Services,
  ...Stripe,
}

export default exports

import { paymentLinks } from 'constants/index'
import { toDateTime } from './date'

export const formatPaymentLink = (id) => {
  return `${paymentLinks.baseUrl}/payment/${id}`
}

function formatPaymentStatus(status) {
  switch (status) {
    case 'paid':
      return 'Paid'
    case 'refunded':
      return 'Refunded'
    default:
      return 'Not Paid'
  }
}

export const formatPaymentLinksForCsv = (rows) => {
  return rows.map((row) => ({
    payment_link_id: row.id,
    reference_id: row.referenceId,
    status: formatPaymentStatus(row.paymentStatus),
    amount: (Number(row.amount) / 100).toFixed(2),
    payment_link_url: formatPaymentLink(row.id),
    created_at: toDateTime(row.createdAt, true),
    paid_at: row.paymentSucceededTimestamp
      ? toDateTime(row.paymentSucceededTimestamp, true)
      : 'N.A.',
  }))
}

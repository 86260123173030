import { useState } from 'react'
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { usePaymentLinksTable } from 'contexts/paymentLinksTable.context'
import { jsonToCsv, downloadCsv } from 'utils/csv'
import { formatPaymentLinksForCsv } from 'utils/payment'
import { ChevronDownIcon } from '@chakra-ui/icons'

const ExportLinks = () => {
  const [loading, setLoading] = useState(false)
  const { table } = usePaymentLinksTable()

  const downloadFiltered = () => {
    setLoading(true)
    const rows = formatPaymentLinksForCsv(table.filteredRows.map((row) => row.original))
    downloadCsv(jsonToCsv(rows), 'paysg_filtered_rows.csv')

    setTimeout(() => setLoading(false), 1000)
  }

  const downloadUnpaid = () => {
    setLoading(true)
    const rows = formatPaymentLinksForCsv(
      table.preFilteredRows
        .map((row) => row.original)
        .filter((row) => !row.paymentSucceededTimestamp)
    )
    downloadCsv(jsonToCsv(rows), 'paysg_unpaid_rows.csv')

    setTimeout(() => setLoading(false), 1000)
  }

  return (
    <Menu>
      <MenuButton
        colorScheme="primary"
        variant="outline"
        disabled={table.rows.length === 0}
        isLoading={loading}
        loadingText="processing..."
        as={Button}
        rightIcon={<ChevronDownIcon />}
      >
        Export
      </MenuButton>
      <MenuList>
        <MenuItem onClick={downloadFiltered}>Download filtered rows</MenuItem>
        <MenuItem onClick={downloadUnpaid}>Download unpaid rows</MenuItem>
      </MenuList>
    </Menu>
    // <Button
    //     colorScheme="primary"
    //     variant="outline"
    //     disabled={table.rows.length === 0}
    //     onClick={downloadAsCsv}
    //     isLoading={loading}
    //     loadingText="processing..."
    //   >
    //     {table.state.filters.length === 0 || table.rows.length === 0
    //       ? 'Download CSV'
    //       : `Download ${table.rows.length} Entries`}
    //   </Button>
  )
}

export default ExportLinks

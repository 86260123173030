import { createContext, useContext, useMemo } from 'react'
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table'

export const ServicesTableContext = createContext()

export const ServicesTableProvider = ({ children, services }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Payment Service',
        accessor: 'title',
      },
      {
        Header: 'Agency Name',
        accessor: 'agencyName',
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
      },
    ],
    []
  )

  const table = useTable(
    {
      columns,
      data: services,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  )

  return (
    <ServicesTableContext.Provider
      value={{
        table,
      }}
    >
      {children}
    </ServicesTableContext.Provider>
  )
}

export const useServicesTable = () => {
  const context = useContext(ServicesTableContext)
  if (context === undefined) {
    throw new Error('useServicesTable must be used within a ServicesTableProvider')
  }
  return context
}

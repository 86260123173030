import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const dateTimeFormat = 'DD MMM YYYY, hh:mmA'
const sortableDateTimeFormat = 'DD MMMM YYYY hh:mm:ss A'
const dateFormat = 'DD MMM YYYY'
const tz = 'Asia/Singapore'

export const toDate = (currentDateTime) => {
  return dayjs(currentDateTime).tz(tz).format(dateFormat)
}

export const toDateTime = (currentDateTime, forExcel = false) => {
  return dayjs(currentDateTime)
    .tz(tz)
    .format(forExcel ? sortableDateTimeFormat : dateTimeFormat)
}

import React, { createRef } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormLabel,
  VStack,
  Box,
  FormErrorMessage,
} from '@chakra-ui/react'

import 'styles/components/Modal.scss'

export default function CreateLinkModal({ isOpen, onClose, createLink, isSubmitting, errorState }) {
  const referenceIdRef = createRef()
  const amountRef = createRef()
  const descriptionRef = createRef()

  const handleSubmit = () =>
    createLink({
      referenceId: referenceIdRef.current.value,
      amount: amountRef.current.value,
      description: descriptionRef.current.value,
    })

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="2">
        <ModalHeader>Create a New Payment Link</ModalHeader>
        <ModalCloseButton mr="2" mt="2" />
        <ModalBody onKeyPress={handleKeyPress}>
          <VStack spacing={6}>
            <FormControl isRequired isInvalid={errorState.referenceId}>
              <FormLabel fontWeight="bold">Reference ID</FormLabel>
              <Input type="text" ref={referenceIdRef} placeholder="Payment reference ID" />
              <FormErrorMessage>{errorState.referenceId}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errorState.amount}>
              <FormLabel fontWeight="bold">Amount</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                  $
                </InputLeftElement>
                <Input ref={amountRef} placeholder="Enter amount to collect" />
              </InputGroup>
              <FormErrorMessage>{errorState.amount}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errorState.description}>
              <FormLabel fontWeight="bold">Description</FormLabel>
              <Input
                type="text"
                ref={descriptionRef}
                placeholder="Description of product being paid for"
              />
              <FormErrorMessage>{errorState.description}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Box mt="6">
            <Button colorScheme="primary" variant="ghost" mr="4" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              variant="solid"
              onClick={handleSubmit}
              isLoading={isSubmitting}
            >
              Create Link
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

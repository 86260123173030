import React from 'react'

// Components
import Links from './Links'
import LinksEmpty from './LinksEmpty'

const LinksContainer = ({ links, refetch }) => {
  return Array.isArray(links) && links.length > 0 ? (
    <Links links={links} />
  ) : (
    <LinksEmpty refetch={refetch} />
  )
}

export default LinksContainer

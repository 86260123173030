import { Box, Flex, Image, Text, Button } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import paySgGirl from 'img/paySgGirl.svg'

const ServicesEmpty = () => {
  const history = useHistory()

  return (
    <Box bg="white" borderRadius="lg" pt="30px" pb="10px">
      <Flex
        flexDirection="column"
        w="100%"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Flex>
          <Image h="230px" mb={8} src={paySgGirl} />
        </Flex>
        <Flex maxW="360px" mb={5}>
          <Text fontWeight="semibold">
            You have not created or been added to a payment service.
          </Text>
        </Flex>
        <Flex>
          <Button colorScheme="primary" onClick={() => history.push('/services/new')}>
            Create a Payment Service
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}

export default ServicesEmpty

import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/react'

import { useCreatePaymentLinks } from '../../../../../contexts/createPaymentLinks.context'

import BulkCreateLinksUploaderEmpty from './Empty'
import BulkCreateLinksUploaderParseCompleted from './ParseCompleted'
import BulkCreateLinksUploaderCreate from './Create'

export default function BulkCreateLinksUploader({ onCancel, refetch }) {
  /**
   * State management
   */
  const { currentStep, reset, steps } = useCreatePaymentLinks()

  //  On Offload
  useEffect(() => {
    return () => {
      reset()
      onCancel()
    }
  }, [])

  /**
   * Rendering Components
   *
   * There are six states that the uploader can be in
   * 1. Empty: Waiting for csv file
   * 2. ParseInProgress: csv file is being parsed
   * 3. ParseCompleted: csv file has completed parsing
   * 5. Create: Payment links are created and response is shown
   */
  const renderUploaderContent = (step) => {
    switch (steps[step]) {
      case 'ParseCompleted':
        return <BulkCreateLinksUploaderParseCompleted onCancel={onCancel} />

      case 'Create':
        return <BulkCreateLinksUploaderCreate onCancel={onCancel} refetch={refetch} />

      default:
        return <BulkCreateLinksUploaderEmpty onCancel={onCancel} />
    }
  }

  return (
    <Box pt="0" textAlign="center">
      {renderUploaderContent(currentStep)}
    </Box>
  )
}

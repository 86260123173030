import { forwardRef, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
} from '@chakra-ui/react'
import { IoCalendarOutline } from 'react-icons/io5'
import { useAsyncDebounce } from 'react-table'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './Filters.css'

const Filters = ({
  // filters,
  setAllFilters,
  setFilter,
}) => {
  const defaultFilterState = {
    textSearchBy: 'referenceId',
    textSearch: '',
    startDate: undefined,
    endDate: undefined,
    status: 'all',
  }
  const [filterState, setFilterState] = useState({
    ...defaultFilterState,
  })
  const resetFilters = () => {
    setAllFilters([])
    setFilterState({ ...defaultFilterState })
  }

  const updateDatePicker = (type, date) => {
    if (type === 'startDate') {
      setFilterState({
        ...filterState,
        startDate: date,
      })
      setFilter('createdAt', [date, filterState.endDate])
    } else {
      setFilterState({
        ...filterState,
        endDate: date,
      })
      setFilter('createdAt', [filterState.startDate, date])
    }
  }

  const updateStatus = (e) => {
    setFilterState({
      ...filterState,
      status: e.target.value,
    })
    setFilter('paymentStatus', e.target.value)
  }

  const updateTextSearchBy = (e) => {
    setFilterState({
      ...filterState,
      textSearchBy: e.target.value,
    })
    //  Reset text filter
    setFilter('referenceId', undefined)
    setFilter('id', undefined)
    setFilter(e.target.value, filterState.textSearch)
  }

  const updateFilters = useAsyncDebounce((e) => {
    switch (e.target.id) {
      case 'textsearch':
        //  Reset text filter
        setFilter('referenceId', undefined)
        setFilter('id', undefined)
        setFilter(filterState.textSearchBy, e.target.value)
        break

      default:
        console.log(e.target.value)
        break
    }
  }, 300)

  const updateTextSearch = (e) => {
    setFilterState({
      ...filterState,
      textSearch: e.target.value,
    })
    updateFilters(e)
  }

  const renderDatePickerInput = (props) => (
    <InputGroup>
      <InputLeftElement>
        <IoCalendarOutline color="gray.300" />
      </InputLeftElement>
      <Input bgColor="white" {...props} />
    </InputGroup>
  )

  const DatePickerInput = forwardRef(renderDatePickerInput)

  return (
    <VStack bgColor="neutral.100" borderRadius={8} py={6} px={5} mb={7}>
      <HStack w="100%" mb={2} alignItems="flex-start">
        <Box w="100%">
          <FormControl id="textsearch">
            <Heading as="h2" size="sm" color="primary.800" textAlign="left" mb={3}>
              Search
            </Heading>
            <InputGroup>
              <InputLeftElement w="200px" zIndex="1">
                <Select
                  bgColor="white"
                  borderTopRightRadius="0px"
                  borderBottomRightRadius="0px"
                  focusBorderColor="none"
                  placeholder="Select option"
                  onChange={updateTextSearchBy}
                  value={filterState.textSearchBy}
                >
                  <option value="referenceId">By Reference ID</option>
                  <option value="id">By Payment ID</option>
                </Select>
              </InputLeftElement>
              <Input
                ml="200px"
                pl={3}
                placeholder="Enter your search query here"
                bgColor="white"
                borderTopLeftRadius="0px"
                borderBottomLeftRadius="0px"
                borderLeft="none"
                focusBorderColor="none"
                onChange={updateTextSearch}
                value={filterState.textSearch}
              />
            </InputGroup>
          </FormControl>
        </Box>
      </HStack>
      <HStack w="100%" alignItems="flex-start">
        <Box w="67%">
          <Heading as="h2" size="sm" color="primary.800" textAlign="left" mb={3}>
            Created At
          </Heading>
          <HStack>
            <FormControl>
              <DatePicker
                id="startDate"
                isClearable
                selected={filterState.startDate}
                customInput={<DatePickerInput />}
                onChange={(date) => updateDatePicker('startDate', date)}
                dateFormat="dd MMM yyyy"
                dateFormatCalendar="MMM yyyy"
                maxDate={Date.now}
                locale="en"
                style={{ width: '100%' }}
                placeholderText="From"
              />
            </FormControl>
            <FormControl>
              <DatePicker
                id="endDate"
                isClearable
                customInput={<DatePickerInput />}
                selected={filterState.endDate}
                onChange={(date) => updateDatePicker('endDate', date)}
                dateFormat="dd MMM yyyy"
                dateFormatCalendar="MMM yyyy"
                maxDate={Date.now}
                locale="en"
                placeholderText="To"
              />
            </FormControl>
          </HStack>
        </Box>
        <Box w="33%">
          <FormControl id="status">
            <Heading as="h2" size="sm" color="primary.800" textAlign="left" mb={3}>
              Status
            </Heading>
            <Select bgColor="white" value={filterState.status} onChange={updateStatus}>
              <option value="all">All</option>
              <option value="paid">Paid</option>
              <option value="not-paid">Not Paid</option>
              <option value="refunded">Refunded</option>
            </Select>
          </FormControl>
        </Box>
      </HStack>
      <HStack w="100%" pt={3}>
        <Button variant="link" fontSize="md" colorScheme="primary" onClick={resetFilters}>
          Reset All Filters
        </Button>
      </HStack>
    </VStack>
  )
}

export default Filters

import { useCallback, useState } from 'react'

// Services
import PayApi from 'services/PayApi'

export default function usePaymentsData() {
  const [payments, setPayments] = useState([])

  const fetch = useCallback(async (serviceId) => {
    const fetchedPayments = await PayApi.listPayments({ serviceId })
    setPayments(
      fetchedPayments.map((payment) => {
        if (payment.chargeRefundedTimestamp) {
          return {
            paymentStatus: 'refunded',
            ...payment,
          }
        }
        if (payment.paymentSucceededTimestamp) {
          return {
            paymentStatus: 'paid',
            ...payment,
          }
        }
        return {
          paymentStatus: 'not-paid',
          ...payment,
        }
      })
    )
  }, [])

  return {
    payments,
    fetch,
  }
}

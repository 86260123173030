import { Box, Button, Stack, Text } from '@chakra-ui/react'
import { jsonToCsv, downloadCsv } from 'utils/csv'

const BulkCreateLinksUploaderInstructions = () => {
  /**
   * Constructs the csv template to be downloaded.
   * @todo consider uploading a raw .csv file to the assets folder and downloading it instead
   */
  const downloadTemplateCsv = () => {
    const csv = jsonToCsv([
      {
        reference_id: 'abc',
        amount: 100,
        description: 'This is a short description',
      },
      {
        reference_id: 'abc123',
        amount: 180,
        description: 'This is a short description',
      },
      {
        reference_id: 'xyz',
        amount: 10.9,
        description: 'This is a short description',
      },
    ])

    downloadCsv(csv, 'sample_template.csv')
  }

  return (
    <Box textAlign="left" mb={6}>
      <Box>
        <Text mb={5}>
          To create payment links, upload a CSV that contains the list of payment links and their
          relevant information. Each row in the CSV represents 1 payment link. The CSV file contains
          the following columns:
        </Text>

        <Stack direction="column" mb={5}>
          <Stack direction="row">
            <Text w="20%" fontWeight="bold" color="gray.700">
              reference_id<sup>*</sup>
            </Text>
            <Text color="gray.700">Unique text to identify this payment link</Text>
          </Stack>
          <Stack direction="row">
            <Text w="20%" fontWeight="bold" color="gray.700">
              amount<sup>*</sup>
            </Text>
            <Text color="gray.700">Amount in dollars to be collected for the payment link</Text>
          </Stack>
          <Stack direction="row">
            <Text w="20%" fontWeight="bold" color="gray.700">
              description<sup>*</sup>
            </Text>
            <Text color="gray.700">Text to describe the product being paid for</Text>
          </Stack>
          <Stack direction="row">
            <Text w="100%" color="gray.700" fontStyle="italic" mt={2}>
              * - Required fields
            </Text>
          </Stack>
        </Stack>

        <Text mb={5}>
          You can also download a template CSV file using the button below to get an idea of what it
          should look like.
        </Text>
      </Box>
      <Box>
        <Button colorScheme="primary" variant="outline" px="6" onClick={downloadTemplateCsv}>
          Download CSV Template
        </Button>
      </Box>
    </Box>
  )
}

export default BulkCreateLinksUploaderInstructions

import React, { useEffect } from 'react'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Heading,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import _ from 'lodash'

// Shared Components
import Header from 'components/Header'
import Loading from 'components/Loading'

// Components
import useServicesData from 'data/useServicesData'
import LinksTab from './components/LinksTab'
import SettingsTab from './components/SettingsTab'
import AdministratorsTab from './components/AdministratorsTab'

// Data

export default function PaymentService() {
  const { services, fetch } = useServicesData()
  const { serviceId } = useParams()

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <>
      <Header bg="white" />

      {services && !_.isEmpty(services[serviceId]) ? (
        <Tabs colorScheme="primary" bg="neutral.100">
          <Box mb="4" pt="4" bg="white">
            <Container maxWidth="96em">
              <Breadcrumb pb={2} color="gray.600">
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="/services">
                    All Payment Services
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink isCurrentPage>{services[serviceId].title}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              <Heading pb={4}>{services[serviceId].title}</Heading>
              <TabList border="none" ml="-15px">
                {['Links', 'Settings', 'Admins'].map((title) => {
                  return (
                    <Tab
                      key={title}
                      fontSize="1.2rem"
                      fontWeight="600"
                      color="gray.500"
                      _focus={false}
                      mr="2"
                      _selected={{
                        borderColor: 'secondary.600',
                        color: 'gray.800',
                      }}
                    >
                      {title}
                    </Tab>
                  )
                })}
              </TabList>
            </Container>
          </Box>
          <Container maxWidth="96em">
            <Box mt="8" justify="center" align="center">
              <TabPanels bg="neutral.100" borderColor="neutral.200" pb="5">
                <TabPanel p={0}>
                  <LinksTab service={services[serviceId]} />
                </TabPanel>
                <TabPanel p={0}>
                  <SettingsTab services={services} fetch={fetch} />
                </TabPanel>
                <TabPanel p={0}>
                  <AdministratorsTab />
                </TabPanel>
              </TabPanels>
            </Box>
          </Container>
        </Tabs>
      ) : (
        <Loading />
      )}
    </>
  )
}

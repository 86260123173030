import { createContext, useContext, useMemo } from 'react'
import { Flex } from '@chakra-ui/react'
import {
  useFilters,
  useGlobalFilter,
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
} from 'react-table'
import dayjs from 'dayjs'
// import { matchSorter } from 'match-sorter'

// import { parseCsvFile, countEntries } from "../utils/csv"

export const PaymentLinksTableContext = createContext()

export const PaymentLinksTableProvider = ({ children, links }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Ref. Id',
        accessor: 'referenceId',
        filter: 'text',
      },
      {
        Header: 'Payment. Id',
        accessor: 'id',
        filter: 'text',
      },
      {
        Header: (
          <Flex w="100%" justifyContent="flex-end">
            Amount
          </Flex>
        ),
        accessor: 'amount',
      },
      {
        Header: 'Status',
        accessor: 'paymentStatus',
        filter: 'status',
        disableSortBy: true,
      },
      /*
      {
        Header: 'Description',
        accessor: 'description',
        filter: 'fuzzyText',
      },
      {
        Header: 'Email',
        accessor: 'email',
        filter: 'fuzzyText',
      },
      {
        Header: () => {
          return <Text>Last&nbsp;Email&nbsp;Sent&nbsp;<Tooltip
              label="This is the last time the email was sent by PaySG. The email could have been rejected by the recipient's mailbox provider."
              py={2}
              px={4}
            >
              <QuestionOutlineIcon/>
            </Tooltip>
          </Text>
        },
        accessor: 'lastSentTimestamp',
      },
      */
      {
        Header: 'Created At',
        accessor: 'createdAt',
        filter: 'betweenDates',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableSortBy: true,
      },
    ],
    []
  )

  /*
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )
  */

  /**
   * Fuzzy text filter
   *
   * @param {*} rows
   * @param {*} id
   * @param {*} filterValue
   * @returns
   */
  /*
   const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, {
      keys: [
        row => row.values[id]
      ]
    })
  }
  fuzzyTextFilterFn.autoRemove = val => !val
  */

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      status: (rows, id, filterValue) => {
        if (filterValue === 'all') {
          return rows
        }
        return rows.filter((row) => row.values[id] === filterValue)
      },
      betweenDates(rows, id, filterValues) {
        //  Start Date
        const sd = filterValues[0] ? dayjs(new Date(filterValues[0])).startOf('day') : undefined
        //  End Date
        const ed = filterValues[1] ? dayjs(new Date(filterValues[1])).endOf('day') : undefined

        if (ed || sd) {
          return rows.filter((row) => {
            const time = new Date(row.values[id])

            if (ed && sd) {
              return time >= sd && time <= ed
            }
            if (sd) {
              return time >= sd
            }
            //  Assumes end date
            return time <= ed
          })
        }
        return rows
      },
    }),
    []
  )

  const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
    const count = preFilteredRows.length

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const table = useTable(
    {
      columns,
      data: links,
      defaultColumn,
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'createdAt',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
    /*
    (hooks) => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
    */
  )

  return (
    <PaymentLinksTableContext.Provider
      value={{
        table,
      }}
    >
      {children}
    </PaymentLinksTableContext.Provider>
  )
}

export const usePaymentLinksTable = () => {
  const context = useContext(PaymentLinksTableContext)
  if (context === undefined) {
    throw new Error('usePaymentLinksTable must be used within a PaymentLinksTableProvider')
  }
  return context
}

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import Storage from 'services/Storage'

const CurrentAdmins = ({ admins, removeServiceAdmin }) => {
  const { serviceId } = useParams()
  const [removingAdmin, setRemovingAdmin] = useState([])
  const { user } = Storage.loadUser()

  /**
   * removes an admin from the service by id
   *
   * @param {string} userId
   */
  const removeAdminFromService = async (userId, email) => {
    if (window.confirm(`Please confirm that you would like to remove ${email}`)) {
      setRemovingAdmin((currentAdmins) => {
        return [...currentAdmins, userId]
      })
      try {
        await removeServiceAdmin({ serviceId, userId })
      } catch (error) {
        alert(error.message)
      } finally {
        setRemovingAdmin(removingAdmin.filter((id) => id !== userId))
      }
    }
  }

  const renderSingleAdmin = (admin) => {
    return (
      <HStack
        key={admin.id}
        w="100%"
        lineHeight="50px"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #e3e3e3"
      >
        <Text>{admin.email}</Text>
        {admin.email && admin.email !== user.email ? (
          <Button
            isLoading={removingAdmin.includes(admin.id)}
            loadingText="Removing..."
            variant="outline"
            colorScheme="primary"
            size="xs"
            onClick={() => removeAdminFromService(admin.id, admin.email)}
          >
            Remove
          </Button>
        ) : null}
      </HStack>
    )
  }

  return (
    <VStack mb={12} align="flex-start">
      <Heading as="h2" size="md" color="gray.900">
        Existing&nbsp;Admins
      </Heading>
      <Divider mb={0} />
      {admins.map(renderSingleAdmin)}
    </VStack>
  )
}

export default CurrentAdmins

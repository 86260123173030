import Keys from 'constants/storage'

function saveUser(user) {
  return localStorage.setItem(Keys.USER, JSON.stringify(user))
}

function loadUser() {
  return JSON.parse(localStorage.getItem(Keys.USER))
}

function clearUser() {
  return localStorage.removeItem(Keys.USER)
}

function isUserSaved() {
  return Keys.USER in localStorage
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  saveUser,
  loadUser,
  clearUser,
  isUserSaved,
}

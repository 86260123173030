import { Box, Container, Flex, Heading, Image, Text, Button, SimpleGrid } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import payWelcome from 'img/Paysg_Welcome.svg'

import Header from 'components/Header'
import ServicesTable from './components/ServicesTable'

export default function Home() {
  const history = useHistory()
  const handleCreateNew = () => history.push('/services/new')

  return (
    <>
      <Header />

      <Box zIndex="999" position="relative">
        <Container maxW="96em" mt="2" mb="5">
          <Box>
            <Box
              bg="background: #354fb7; /* Old browsers */
              background: -moz-linear-gradient(-45deg, #354fb7 0%, #56527c 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(-45deg, #354fb7 0%,#56527c 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(135deg, #354fb7 0%,#56527c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */"
              w="100%"
              color="white"
              borderRadius="xl"
              overflow="hidden"
              px="16"
              py={10}
            >
              <SimpleGrid columns={2} spacing={10}>
                <Flex direction="column" align="flex-start" justify="center">
                  <Heading as="h1" size="2xl" mb="5">
                    Welcome back
                  </Heading>
                  <Text fontSize="lg" mb="8">
                    PaySG is a payment collection portal for government agencies. The best way to
                    get started is to create your first payment service and try it out!
                  </Text>
                  <Button colorScheme="white" size="lg" variant="outline" onClick={handleCreateNew}>
                    Create a New Payment Service
                  </Button>
                </Flex>
                <Flex justify="flex-end">
                  <Image src={payWelcome} position="absolute" bottom={0} w="375px" />
                </Flex>
              </SimpleGrid>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        w="100%"
        pt="13rem"
        pb="5rem"
        mt="-200px"
        bg="neutral.100"
        borderTop="1px"
        borderColor="gray.200"
        zIndex="0"
        position="relative"
      >
        <Container maxWidth="96em">
          {/* List of payment services here */}
          <Box bg="white" borderRadius="xl" boxShadow="0px 4px 40px rgba(0, 0, 0, 0.1);" py="6">
            <Box mx="6">
              <Heading as="h1" size="xl" mt="3" mb="8">
                All Payment Services
              </Heading>
              <ServicesTable />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

import baseConfig from './baseConfig'

/**
 * @todo move to async/await
 */

/**
 * Request for OTP by email
 *
 * @param {*} param0
 * @returns
 */
export const requestOtpByEmail = ({ email }) => {
  console.log('[INFO] requestOtpByEmail')
  return baseConfig.post('/auth/otp/register', {
    email,
  })
}

/**
 * Authenticates the user with the OTP
 *
 * @param {*} param0
 * @returns
 */
export const authenticateCredentials = ({ otp, email }) => {
  console.log('[INFO] authenticateCredentials')
  return baseConfig
    .post('/auth/otp/validate', {
      otp,
      email,
    })
    .then((res) => res.data)
}

/**
 * Logs out the user on the server
 *
 * @returns
 */
export const logoutUser = () => {
  console.log('[INFO] logoutUser')
  return baseConfig.post('/auth/logout')
}

import React from 'react'
import { Badge } from '@chakra-ui/react'

export default function LinkPaymentIntentStatus({ paymentStatus }) {
  /**
   * Maps the api payment intent status to a user-friendly version
   */
  const mapPaymentIntentStatus = () => {
    switch (paymentStatus) {
      case 'paid':
        return {
          colorScheme: 'green',
          color: 'white',
          text: 'Paid',
        }
      case 'refunded':
        return {
          text: 'Refunded',
        }
      default:
        // case 'not-paid':
        return {
          colorScheme: 'blue',
          color: 'white',
          text: 'Not Paid',
        }
    }
  }
  const status = mapPaymentIntentStatus()

  return (
    <Badge px={2} {...(status.colorScheme && { colorScheme: status.colorScheme })}>
      {status.text}
    </Badge>
  )
}

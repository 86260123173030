import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react'

const NewAdmin = ({ addServiceAdmin }) => {
  const { serviceId } = useParams()

  const [isAdding, setIsAdding] = useState(false)
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState()

  /**
   * Adds a new admin
   * @param {event} e
   */
  const addNewAdmin = async (e) => {
    e.preventDefault()
    setErrors()
    setIsAdding(true)

    try {
      await addServiceAdmin({ serviceId, email })
      setEmail('')
    } catch (error) {
      setErrors(error.message)
    } finally {
      setIsAdding(false)
    }
  }

  /**
   * Change the email state
   *
   * @param {event} e
   */
  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  return (
    <VStack mb={12} align="flex-start">
      <Heading as="h2" size="md" color="gray.900">
        New&nbsp;Admin
      </Heading>
      <Divider mb={4} />
      <form
        style={{
          width: '100%',
        }}
        onSubmit={addNewAdmin}
      >
        <HStack w="100%" align="top">
          <FormControl id="email" isInvalid={errors}>
            <Input
              h="3em"
              type="email"
              isDisabled={isAdding}
              placeholder="Enter gov.sg email address"
              onChange={onChangeEmail}
              value={email}
            />
            <FormErrorMessage>{errors}</FormErrorMessage>
          </FormControl>
          <Button
            h="3em"
            px={10}
            type="submit"
            isLoading={isAdding}
            loadingText="Adding..."
            colorScheme="primary"
          >
            Add
          </Button>
        </HStack>
      </form>
    </VStack>
  )
}

export default NewAdmin

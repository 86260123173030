import React from 'react'

import { useHistory } from 'react-router-dom'
import { Box, Container, Flex, Image, Button, Link } from '@chakra-ui/react'

import productLogo from 'img/paysgLogo.svg'
import PayApi from '../services/PayApi'
import Storage from '../services/Storage'

const Header = ({ background }) => {
  const history = useHistory()

  const handleClick = async () => {
    await Storage.clearUser()
    await PayApi.logoutUser()
    history.push(`/`)
  }

  return (
    <Box w="100%" bg={background}>
      <Container maxW="96em">
        <Flex justify="space-between" py="5">
          <Flex justify="center" align="center">
            <Link href="/">
              <Image w="180px" src={productLogo} />
            </Link>
          </Flex>
          <Flex>
            <Button colorScheme="primary" variant="outline" size="md" onClick={handleClick}>
              Logout
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Header

import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  FormErrorMessage,
  Grid,
  GridItem,
  Image,
  Heading,
  HStack,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  Stack,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { IoRadioButtonOnSharp } from 'react-icons/io5'
import { camelize, decamelizeKeys } from 'humps'

import step1 from 'img/create_step1.svg'
import PayApi from 'services/PayApi'
import Header from 'components/Header'

export default function CreatePaymentService() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorState, setErrorState] = useState({})
  const [formState, setFormState] = useState({
    serviceTitle: '',
    serviceInformation: '',
    serviceAgencyName: '',
    serviceAgencyEmail: '',
    serviceAgencyAddress: '',
    serviceAgencyGstRegNum: '',
  })
  const toast = useToast()

  const updateFormField = (e) => {
    const newFormState = { ...formState }
    newFormState[camelize(e.target.name)] = e.target.value
    setFormState(newFormState)
  }

  const createService = async (e) => {
    e.preventDefault()
    setErrorState({})
    setIsSubmitting(true)
    try {
      const service = await PayApi.createService({
        ...formState,
      })
      toast({
        title: 'Success',
        description: `Payment service "${service.title}" was created successfully.`,
        position: 'top',
        status: 'success',
        variant: 'subtle',
        isClosable: true,
      })
      history.push('/services')
    } catch (error) {
      if (typeof error?.response?.data?.error?.data === 'object') {
        const errors = decamelizeKeys(error?.response?.data?.error?.data)
        setErrorState(errors)
      }
      setIsSubmitting(false)
    }
  }
  const cancelCreate = () => history.push('/services')

  return (
    <Box>
      <Header bg="white" />
      <Box bg="white" pb={8}>
        <Container maxW="96em">
          <Box mb="2" pt="4">
            <Breadcrumb color="gray.600">
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to="/services">
                  All Payment Services
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink isCurrentPage>New Service</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Box>
          <Box>
            <Heading as="h1" size="xl">
              Create a New Payment Service
            </Heading>
          </Box>
        </Container>
      </Box>
      <Box bg="neutral.100">
        <Container maxWidth="96em" py={12}>
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            <GridItem colSpan={1}>
              <HStack mt={3}>
                <Flex mr={2}>
                  <IoRadioButtonOnSharp size={27} color="#283A90" />
                </Flex>
                <Flex flexDir="column">
                  <Text fontSize="xs" color="primary.500">
                    Step 1
                  </Text>
                  <Heading size="sm" color="primary.500">
                    Set Up Payment Service
                  </Heading>
                </Flex>
              </HStack>
            </GridItem>
            <GridItem
              colSpan={3}
              bg="white"
              borderRadius="lg"
              p="8"
              boxShadow="0px 4px 40px rgba(0, 0, 0, 0.1);"
            >
              <Box>
                <Flex justify="flex-start" align="center">
                  <Box mr="3">
                    <Image src={step1} />
                  </Box>

                  <Box>
                    <Text color="primary.700">Step 1</Text>
                    <Heading size="lg" color="primary.600">
                      Set Up Payment Service
                    </Heading>
                  </Box>
                </Flex>
              </Box>
              <Box mt="10">
                <form onSubmit={createService}>
                  <VStack spacing={8}>
                    <FormControl isInvalid={errorState.service_title} isRequired>
                      <FormLabel fontWeight="semibold">Payment Service Title</FormLabel>
                      <Input
                        name="service_title"
                        type="text"
                        required
                        onChange={updateFormField}
                        placeholder="e.g. Parking Fines"
                        value={formState.serviceTitle}
                      />
                      <FormErrorMessage>{errorState.service_title}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errorState.service_information} isRequired>
                      <FormLabel fontWeight="semibold">Payment Service Description</FormLabel>
                      <Textarea
                        name="service_information"
                        type="text"
                        required
                        onChange={updateFormField}
                        placeholder="A short description about your payment service. This will appear on the payment link"
                        value={formState.serviceInformation}
                      />
                      <FormErrorMessage>{errorState.service_information}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errorState.service_agency_name} isRequired>
                      <FormLabel fontWeight="semibold">Agency Name</FormLabel>
                      <Input
                        name="service_agency_name"
                        type="text"
                        required
                        onChange={updateFormField}
                        placeholder="Agency Name"
                        value={formState.serviceAgencyName}
                      />
                      <FormErrorMessage>{errorState.service_agency_name}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errorState.service_agency_email} isRequired>
                      <FormLabel fontWeight="semibold">Agency Contact Email</FormLabel>
                      <Input
                        name="service_agency_email"
                        type="email"
                        required
                        onChange={updateFormField}
                        placeholder="Agency Contact Email"
                        value={formState.serviceAgencyEmail}
                      />
                      <FormErrorMessage>{errorState.service_agency_email}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errorState.service_agency_address} isRequired>
                      <FormLabel fontWeight="semibold">Agency Address</FormLabel>
                      <Input
                        name="service_agency_address"
                        type="text"
                        required
                        onChange={updateFormField}
                        placeholder="Agency Address"
                        value={formState.serviceAgencyAddress}
                      />
                      <FormErrorMessage>{errorState.service_agency_address}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errorState.service_agency_gst_reg_num} isRequired>
                      <FormLabel fontWeight="semibold">Agency GST Registration Number</FormLabel>
                      <Input
                        name="service_agency_gst_reg_num"
                        type="text"
                        required
                        onChange={updateFormField}
                        placeholder="Agency GST Registration Number"
                        value={formState.serviceAgencyGstRegNum}
                      />
                      <FormErrorMessage>{errorState.service_agency_gst_reg_num}</FormErrorMessage>
                    </FormControl>
                    {/*
                    <Box w="100%">
                      <FormControl>
                        <FormLabel>Select Bank Account</FormLabel>
                        <FormHelperText mb="5">
                          If you do not find your agency bank account here,
                          please fill up this form. If not you can select the
                          test bank account to test out the full flow.
                        </FormHelperText>
                      </FormControl>

                      <Select placeholder="Select option">
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                      </Select>
                    </Box>
                    */}
                  </VStack>
                  <Box my="8">
                    <Stack direction="row" spacing={2} align="center" justify="flex-start">
                      <Button
                        colorScheme="primary"
                        variant="solid"
                        px={6}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                        type="submit"
                      >
                        Create
                      </Button>
                      <Button colorScheme="primary" variant="ghost" onClick={cancelCreate}>
                        Cancel
                      </Button>
                    </Stack>
                  </Box>
                </form>
              </Box>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

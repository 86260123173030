import React, { useEffect, useState } from 'react'
import { Button, HStack, Input, Text, useToast } from '@chakra-ui/react'

export default function GenericEditableField({ fieldKey, fieldName, fieldValue, onSave }) {
  const [originalValue, setOriginalValue] = useState('')
  const [value, setValue] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [, setIsSubmitting] = useState(false)
  const toast = useToast()

  // Display the field value once it's loaded
  useEffect(() => {
    setValue(fieldValue)
  }, [fieldValue])

  const onEditClick = () => {
    setOriginalValue(value)
    setIsEditing(true)
  }

  const onCancelClick = () => {
    setValue(originalValue)
    setIsEditing(false)
  }

  function onSubmitClick() {
    // Change save button to spinner
    setIsSubmitting(true)

    // Call the onSave function with the fieldKey and value
    onSave({ newFieldKey: fieldKey, newFieldValue: value })
      .then(
        () => {
          setOriginalValue(value)
          setIsEditing(false)
        },
        (err) => {
          if (!toast.isActive(fieldKey)) {
            toast({
              id: fieldKey,
              title: `Error`,
              description: err.response.data.error.message,
              status: 'error',
              variant: 'subtle',
              position: 'top',
              duration: null,
              isClosable: true,
            })
          }
        }
      )
      .finally(setIsSubmitting(false))
  }

  const onKeyPress = (e) => {
    if (e.code === 'Enter') {
      onSubmitClick()
    }
  }

  return (
    <HStack w="100%" align="center">
      <Text w="50%" maxW="250px" textAlign="left" color="gray.700" fontWeight="bold">
        {fieldName}
      </Text>
      <Input
        w="50%"
        maxWidth
        value={value}
        onChange={(evt) => setValue(evt.target.value)}
        onKeyPress={onKeyPress}
        disabled={!isEditing}
        mr={2}
      />
      {!isEditing ? (
        <Button colorScheme="primary" variant="link" size="xs" onClick={onEditClick}>
          Edit
        </Button>
      ) : (
        <>
          <Button
            colorScheme="primary"
            variant="link"
            size="xs"
            onClick={() => onSubmitClick({ fieldKey, value })}
          >
            Save
          </Button>
          <Button colorScheme="red" variant="link" size="xs" onClick={onCancelClick}>
            Cancel
          </Button>
        </>
      )}
    </HStack>
  )
}

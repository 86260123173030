import React from 'react'
import { Button, Container, Flex, Heading, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import Header from 'components/Header'

const PageNotFound = () => {
  const history = useHistory()
  const goHome = () => {
    history.push('/')
  }

  return (
    <>
      <Header />
      <Container
        display="flex"
        height="calc(100vh - 30px - 252px)"
        width="100vw"
        alignItems="center"
        py="5"
      >
        <Flex flexDirection="column" alignItems="center" width="100vw">
          <Heading>404: Not Found</Heading>
          <Text fontSize="xl" my={5}>
            You just hit a route that doesn&#39;t exist.
          </Text>
          <Button onClick={goHome} colorScheme="primary">
            &larr;&nbsp;Return&nbsp;Home
          </Button>
        </Flex>
      </Container>
    </>
  )
}

export default PageNotFound

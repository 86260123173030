import { Flex, Spinner, Text } from '@chakra-ui/react'

const Loading = ({ height = 'calc(100vh - 110px - 252px)' }) => {
  return (
    <Flex
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={height}
    >
      <Flex width="100%" justifyContent="center" alignItems="center" mb={6}>
        <Spinner size="xl" color="primary.600" />
      </Flex>
      <Text color="primary.600" fontSize="1.5em" fontWeight="semibold">
        Loading
      </Text>
    </Flex>
  )
}

export default Loading

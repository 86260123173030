import Loading from 'components/Loading'
import React, { useEffect, useState } from 'react'
import useServicesData from 'data/useServicesData'
import { ServicesTableProvider } from 'contexts/servicesTable.context'
import ServicesEmpty from './ServicesEmpty'
import Services from './Services'

export default function ServicesContainer() {
  const [isLoading, setIsLoading] = useState(true)
  const { servicesArray, fetch } = useServicesData()

  useEffect(() => {
    const fetchServices = async () => {
      setIsLoading(true)
      await fetch()
      setIsLoading(false)
    }

    fetchServices()
  }, [fetch, setIsLoading])

  const renderServices = () => {
    return Array.isArray(servicesArray) && servicesArray.length > 0 ? (
      <ServicesTableProvider services={servicesArray}>
        <Services />
      </ServicesTableProvider>
    ) : (
      <ServicesEmpty />
    )
  }

  return isLoading ? <Loading height="200px" /> : renderServices()
}

import { useCallback, useState } from 'react'

// Services
import PayApi from 'services/PayApi'
import {
  listServiceAdmins,
  addAdminToService,
  removeAdminFromService,
} from 'services/PayApi/Services'

export default function useServicesData() {
  const [services, setServices] = useState({})
  const [servicesArray, setServicesArray] = useState({})
  /**
   * object: Record<ServiceId, {email: string}>
   * @todo inject into services instead
   */
  const [servicesAdmins, setServicesAdmins] = useState({})

  /**
   * fetch the services data
   */
  const fetch = useCallback(async () => {
    const response = await PayApi.listServices()
    const fetchedServices = {}

    if (response) {
      setServicesArray(response)
      response.forEach((service) => {
        fetchedServices[service.id] = service
      })
    }

    setServices(fetchedServices)
  }, [])

  /**
   * Fetches a services' admins
   */
  const fetchServiceAdmins = useCallback(async ({ serviceId }) => {
    //  Expects an array of admins for the services
    const response = await listServiceAdmins({ serviceId })

    setServicesAdmins((newServicesAdmins) => {
      const updatedServicesAdmins = { ...newServicesAdmins }
      updatedServicesAdmins[serviceId] = response || []
      return {
        ...updatedServicesAdmins,
      }
    })
  }, [])

  /**
   * Add admin to service
   *
   * @param {*} param0
   */
  const addServiceAdmin = async ({ serviceId, email }) => {
    try {
      await addAdminToService({ serviceId, email })
      await fetchServiceAdmins({ serviceId })
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        throw Error(error.response.data.error.message)
      } else {
        throw Error('An unexpected error has occurred')
      }
    }
  }

  /**
   * remove admin from service
   */
  const removeServiceAdmin = async ({ serviceId, userId }) => {
    try {
      await removeAdminFromService({ serviceId, userId })

      //  Add a fake sleep
      await new Promise((resolve) => setTimeout(resolve, 500))

      await fetchServiceAdmins({ serviceId })
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        throw Error(error.response.data.error.message)
      } else {
        throw Error('An unexpected error has occurred')
      }
    }
  }

  return {
    addServiceAdmin,
    fetch,
    fetchServiceAdmins,
    removeServiceAdmin,
    services,
    servicesArray,
    servicesAdmins,
  }
}

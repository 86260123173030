import React, { useState } from 'react'
import { Box, Flex, Image, Table, Text, Thead, Tbody, Tr, Th } from '@chakra-ui/react'
import { IoArrowUpOutline, IoArrowDownOutline } from 'react-icons/io5'

import paySgGirl from 'img/paySgGirl.svg'

import { usePaymentLinksTable } from 'contexts/paymentLinksTable.context'

// Components
import Loading from 'components/Loading'
import Pagination from 'components/Pagination'
import Filters from './components/Filters'
import LinkRow from './components/LinkRow'

/**
 * This component is displayed when there are payment links
 *
 * @param {*} param0
 * @returns
 */
const Links = ({ copyLink }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { table } = usePaymentLinksTable()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    rows,
    setAllFilters,
    setFilter,
    setPageSize,
    state: { pageIndex, pageSize },
  } = table

  const setfilter = (key, value) => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    setFilter(key, value)
  }

  const setallfilters = (value) => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    setAllFilters(value)
  }

  const renderSortIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return <IoArrowUpOutline />
      }
      return <IoArrowDownOutline />
    }

    return null
  }

  const renderTable = () => {
    return rows.length > 0 ? (
      <>
        <Table size="md" {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              // Apply the header row props
              <Tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} bg="neutral.100">
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <Th
                      key={column.id}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      p={3}
                    >
                      <Flex alignItems="center">
                        {
                          // Render the header
                          column.render('Header')
                        }
                        {/* Add a sort direction indicator */}
                        <span>{renderSortIcon(column)}</span>
                      </Flex>
                    </Th>
                  ))
                }
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return <LinkRow key={row.id} row={row} copyLink={copyLink} />
            })}
          </Tbody>
        </Table>
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          pageCount={pageCount}
          previousPage={previousPage}
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </>
    ) : (
      <Flex flexDirection="column" w="100%" justifyContent="center" alignItems="center" py={10}>
        <Flex>
          <Image h="180px" mb={6} src={paySgGirl} />
        </Flex>
        <Flex>
          <Text fontWeight="semibold">There are no payment links that match your filters.</Text>
        </Flex>
      </Flex>
    )
  }

  return (
    <Box>
      <Filters setFilter={setfilter} setAllFilters={setallfilters} />
      {isLoading ? <Loading /> : renderTable()}
    </Box>
  )
}

export default Links

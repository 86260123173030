import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Flex, Icon, ModalFooter, Text } from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone'
import { VscFile } from 'react-icons/vsc'

import { useCreatePaymentLinks } from 'contexts/createPaymentLinks.context'
import BulkCreateLinksUploaderInstructions from './Instructions'

const BulkCreateLinksUploaderEmpty = ({ onCancel }) => {
  const { nextStep, parseFile, parsed, total } = useCreatePaymentLinks()
  const [selectedFile, setSelectedFile] = useState()

  /**
   * Transition to next page when all rows in file are parsed
   */
  useEffect(() => {
    if (total > 0 && total === parsed.length) {
      nextStep()
    }
  }, [total, parsed.length, nextStep])

  /**
   * React Dropzone functions
   */
  const onDrop = useCallback((acceptedFiles) => {
    //  reads a single file
    const file = acceptedFiles[0]
    setSelectedFile(file)
  }, [])

  /**
   * Reference: https://react-dropzone.js.org/
   */
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
    maxFiles: 1,
    onDrop,
  })

  /**
   * Component functions
   */
  const validateFile = (file) => {
    parseFile(file)
  }

  /**
   * Presentation component for when uploader is empty
   */
  return (
    <>
      <BulkCreateLinksUploaderInstructions />
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Box
          borderStyle="dashed"
          borderWidth="2px"
          borderRadius="8px"
          p="10"
          _hover={{
            cursor: 'pointer',
            background: 'gray.100',
            color: 'gray.600',
          }}
        >
          {selectedFile && (
            <Flex flexDirection="column" alignItems="center">
              <Text mb={2} fontWeight="bold">
                Selected File:
              </Text>
              <Flex mb="2">
                <Icon as={VscFile} h={10} w={10} color="gray.500" />
              </Flex>
              <Flex flexDirection="column" justifyContent="center" textAlign="left">
                <Text fontWeight="bold">{selectedFile.name}</Text>
              </Flex>
            </Flex>
          )}
          {!selectedFile && (
            <>
              <Button colorScheme="primary">Upload CSV</Button>
              <Text pt="3">
                {isDragActive ? 'Drop file here' : 'You can also drag and drop your CSV file here'}
              </Text>
            </>
          )}
        </Box>
      </Box>
      <ModalFooter p={0}>
        <Box mt="6">
          <Button colorScheme="primary" variant="ghost" mr="4" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            variant="solid"
            onClick={() => {
              validateFile(selectedFile)
            }}
            isLoading={total > 0 && total !== parsed.length}
            isDisabled={selectedFile === undefined}
          >
            Validate File
          </Button>
        </Box>
      </ModalFooter>
    </>
  )
}

export default BulkCreateLinksUploaderEmpty

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import payLogin from 'img/payLogin.svg'
import payLogo from 'img/paysgLogo.svg'

import { HiOutlineMail, HiOutlineLockClosed } from 'react-icons/hi'

import {
  Box,
  Image,
  Button,
  Flex,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormLabel,
  ButtonGroup,
} from '@chakra-ui/react'

import PayApi from 'services/PayApi'
import Storage from 'services/Storage'
import { InfoOutlineIcon } from '@chakra-ui/icons'

// Login has 2 phases
const PHASES = {
  ENTER_EMAIL: 'enter_email',
  ENTER_OTP: 'enter_otp',
}

const EMAIL_MAX_LENGTH = 255
const EMAIL_INPUT_PLACEHOLDER = 'Email Address'
const OTP_MAX_LENGTH = 10
const OTP_PLACEHOLDER = '6 Digit OTP'

const Login = () => {
  const history = useHistory()

  const [phase, setPhase] = useState(PHASES.ENTER_EMAIL)
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [errMsg, setError] = useState('')
  const [isLoading, setLoading] = useState(false)

  const handleEmailChange = (event) => setEmail(event.target.value)
  const handleOtpChange = (event) => setOtp(event.target.value)

  const handleSubmitEmail = async () => {
    setLoading(true)
    setError('')
    try {
      await PayApi.requestOtpByEmail({ email })
      setPhase(PHASES.ENTER_OTP)
    } catch (error) {
      setError(error.response.data.error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitOtp = async () => {
    setLoading(true)
    setError('')
    try {
      const user = await PayApi.authenticateCredentials({ otp, email })
      await Storage.saveUser(user)
      history.push(`/services`)
    } catch (error) {
      setError(error.response.data.error.message)
      setLoading(false)
    }
  }

  const handleBackClick = () => {
    setError('')
    setPhase(PHASES.ENTER_EMAIL)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (phase === PHASES.ENTER_EMAIL) {
        handleSubmitEmail()
      } else {
        handleSubmitOtp()
      }
    }
  }

  const errorMessage = (
    <div
      style={{
        color: 'red',
        paddingTop: 10,
      }}
    >
      <InfoOutlineIcon /> {errMsg}
    </div>
  )

  const EmailLabelAndInput = (
    <FormControl id="email-form" mb={8}>
      <FormLabel>
        <b>Register or login with an official .gov.sg email address.</b>{' '}
      </FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
          <HiOutlineMail color="grey.700" />
        </InputLeftElement>
        <Input
          id="email-input"
          type="email"
          required
          placeholder={EMAIL_INPUT_PLACEHOLDER}
          maxLength={EMAIL_MAX_LENGTH}
          value={email}
          onChange={handleEmailChange}
          onKeyPress={handleKeyPress}
        />
      </InputGroup>
      {errMsg !== '' && errorMessage}
    </FormControl>
  )

  const OtpLabelAndInput = (
    <FormControl id="otp-form" mb={8}>
      <FormLabel>
        <b>Please enter the 6 digit OTP sent to {email}</b>
      </FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
          <HiOutlineLockClosed color="grey.700" />
        </InputLeftElement>
        <Input
          id="otp-input"
          type="text"
          required
          placeholder={OTP_PLACEHOLDER}
          maxLength={OTP_MAX_LENGTH}
          value={otp}
          onChange={handleOtpChange}
          onKeyPress={handleKeyPress}
        />
      </InputGroup>
      {errMsg !== '' && errorMessage}
    </FormControl>
  )

  return (
    <>
      <Box w="100vw" h="100vh">
        <Flex width="100%" height="100%">
          <Flex bg="light.500" width="50%" height="100%" justify="center" align="center">
            <Image src={payLogin} alt="logo" width="50%" ml="40" />
          </Flex>
          <Flex width="50%" height="100%" justify="center" align="center" p="30">
            <Flex
              direction="column"
              justify="center"
              align="flex-start"
              height="100%"
              width="60%"
              mt="-120px"
              ml="-160px "
            >
              <Box mb="8">
                <Image width="240px" src={payLogo} alt="logo" mb="2" />
                <Text>Fuss-free government payments</Text>
              </Box>

              {phase === PHASES.ENTER_EMAIL ? EmailLabelAndInput : OtpLabelAndInput}

              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  size="lg"
                  onClick={phase === PHASES.ENTER_EMAIL ? handleSubmitEmail : handleSubmitOtp}
                  disabled={isLoading}
                >
                  {phase === PHASES.ENTER_EMAIL ? 'Login' : 'Submit'}
                </Button>

                {phase === PHASES.ENTER_OTP && (
                  <Button variants="ghost" size="lg" onClick={handleBackClick} disabled={isLoading}>
                    Cancel
                  </Button>
                )}
              </ButtonGroup>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default Login

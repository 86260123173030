import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Heading, Image, Stack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

// Components
import Loading from 'components/Loading'
import usePaymentsData from 'data/usePaymentsData'
import { PaymentLinksTableProvider } from 'contexts/paymentLinksTable.context'
import step1 from 'img/create_step1.svg'
import CreateLinkModal from './components/CreateLinkModal'
import { BulkCreateLinksModal } from '../BulkCreateLinks'
import Links from './components/LinksTable'
import ExportLinks from './components/LinksTable/components/ExportLinks'

// Data

const LinksTab = ({ service }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { serviceId } = useParams()
  const { payments, fetch } = usePaymentsData()

  const fetchPayments = useCallback(async () => {
    setIsLoading(true)
    await fetch(serviceId)
    setIsLoading(false)
  }, [fetch, serviceId])

  useEffect(() => {
    fetchPayments()
  }, [fetchPayments])

  return (
    <Box
      bg="white"
      borderRadius="lg"
      py={8}
      px={12}
      my={6}
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.1);"
    >
      <PaymentLinksTableProvider links={payments}>
        <Flex mb="8" align="center" justify="space-between">
          <Flex justify="flex-start" align="center">
            <Box mr="3">
              <Image src={step1} />
            </Box>
            <Box>
              <Heading as="h2" size="lg" color="primary.800">
                Payment Links
              </Heading>
            </Box>
          </Flex>

          <Stack direction="row" spacing={4} align="center" justify="flex-end">
            <ExportLinks />
            <BulkCreateLinksModal service={service} refetch={fetchPayments} />
            <CreateLinkModal service={service} refetch={fetchPayments} />
          </Stack>
        </Flex>

        {isLoading ? <Loading /> : <Links links={payments} refetch={fetchPayments} />}
      </PaymentLinksTableProvider>
    </Box>
  )
}

export default LinksTab

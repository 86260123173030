import React from 'react'
import { ChakraProvider, Box } from '@chakra-ui/react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// customising chakra ui theme with pay colors and font
import Theme from 'Theme'

// import "styles/styles.scss";
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Masthead from 'commons/Masthead'
import OgpFooter from 'commons/OgpFooter'
import TestModeBanner from 'commons/TestModeBanner'
import Login from 'pages/Login'
import Home from 'pages/Home'
import PaymentService from 'pages/PaymentService'
import CreatePaymentService from 'pages/CreatePaymentService'
import FakeForm from 'pages/FakeForm'
import PageNotFound from 'pages/NotFound'

// Import route components
import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

function App() {
  return (
    <Elements stripe={stripePromise}>
      <ChakraProvider theme={Theme}>
        <Box bg="white">
          <Masthead />
          {process.env.REACT_APP_ENV !== 'production' && <TestModeBanner />}
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <PublicRoute path="/login" component={Login} restricted exact />
              <PrivateRoute path="/services/new" component={CreatePaymentService} />
              <PrivateRoute path="/services/:serviceId" component={PaymentService} />
              <PrivateRoute path="/services" component={Home} />
              <PublicRoute path="/fakeform" component={FakeForm} restricted={false} />

              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </Box>

        <OgpFooter />
      </ChakraProvider>
    </Elements>
  )
}

export default App

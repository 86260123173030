import React, { useEffect } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Progress,
  Stack,
  Text,
} from '@chakra-ui/react'
import { IoCheckmarkCircle, IoCloseCircle } from 'react-icons/io5'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { useCreatePaymentLinks } from '../../../../../contexts/createPaymentLinks.context'
import { jsonToCsv, downloadCsv } from '../../../../../utils/csv'

export default function BulkCreateLinksUploaderCreateInProgress({ onCancel = () => {}, refetch }) {
  const { total, errored, processed, created, startCreate } = useCreatePaymentLinks()

  let hasDownloadedLogs = false

  /**
   * On Mount
   */
  useEffect(() => {
    startCreate()
  }, [])

  useEffect(() => {
    if (total === processed.length) {
      refetch()
    }
  }, [processed, refetch, total])

  /**
   * Downloads the error logs
   */
  const downloadLogs = () => {
    hasDownloadedLogs = true

    /**
     * @todo remove after demo
     */
    const csv = jsonToCsv(processed)
    downloadCsv(csv, 'paysg_payment_links_logs.csv')
  }

  /**
   * Closes the modal
   */
  const close = () => {
    if (
      errored.length === 0 ||
      hasDownloadedLogs ||
      window.confirm(
        'Please confirm that you want to close without downloading the error logs. You will not be able to retrieve the logs again after closing this modal.'
      )
    ) {
      onCancel()
    }
  }

  /**
   * Render Progress Bar
   */
  const renderProgressBar = () => {
    return (
      <Stack>
        <Box>
          <Text
            float="left"
            fontSize="0.9rem"
            fontWeight="bold"
            lineHeight="1"
            color={total > processed.length ? 'blue' : 'green'}
          >
            {total > processed.length
              ? `Processing ${processed.length} of ${total} payment links...`
              : 'Completed'}
          </Text>
        </Box>
        <Progress
          isAnimated
          value={(processed.length * 100) / total}
          size="md"
          borderRadius="10px"
          hasStripe={total > processed.length}
          colorScheme="primary"
          mb={6}
        />
      </Stack>
    )
  }

  /**
   * Render successfully created
   */
  const renderCreateSuccessful = () => {
    if (created.length > 0) {
      return (
        <Box display="flex" justifyItems="flex-start" alignItems="center" mt="1">
          <IoCheckmarkCircle color="green" />
          <Text display="flex" alignItems="center" ml="3" fontSize="sm">
            {created.length} payment links successfully created
          </Text>
        </Box>
      )
    }
    return null
  }

  /**
   * Renders any errors
   */
  const renderCreateErrors = () => {
    if (errored.length > 0) {
      return (
        <Box display="flex" justifyItems="flex-start" alignItems="center" mt="1">
          <IoCloseCircle color="red" />
          <Text display="flex" alignItems="center" ml="3" fontSize="sm">
            {errored.length}&nbsp;failed.{' '}
            {total > processed.length
              ? 'We will collate a list for your reference when this job is completed.'
              : null}
          </Text>
        </Box>
      )
    }
    return null
  }

  /**
   * Renders the info box below the number of successfully created/errors
   */
  const renderInfoBox = () => {
    if (total > processed.length) {
      return (
        <Box mb={6} textAlign="left">
          <Text>
            Please wait for all payment links to be created. Closing this page will interrupt the
            link creation process. Thank you for your patience.
          </Text>
        </Box>
      )
    }
    if (errored.length > 0) {
      return (
        <Alert
          status="error"
          mb={6}
          p={4}
          borderRadius={8}
          bgColor="red.100"
          textAlign="left"
          alignItems="flex-start"
        >
          <AlertIcon ml={2} />
          <AlertDescription ml={2}>
            Failed to create {errored.length} payment links. Please download the error logs and fix
            the data before uploading these errored payment links to be created again.
          </AlertDescription>
        </Alert>
      )
    }
    return null
  }

  /**
   * Renders a single invalid row
   *
   * @returns
   */
  const InvalidRow = ({ index, style }) => {
    const row = errored[index]
    return (
      <Flex
        key={index}
        style={style}
        fontSize="sm"
        alignItems="center"
        bgColor={index % 2 ? 'gray.100' : ''}
      >
        <Flex width="15%" px={1}>
          {row.reference_id}
        </Flex>
        <Flex width="15%" px={1}>
          ${Number(row.amount).toFixed(2)}
        </Flex>
        <Flex width="40%" px={1} overflow="hidden">
          {row.errors}
        </Flex>
      </Flex>
    )
  }

  /**
   * Renders only the invalid rows
   *
   * @returns
   */
  const renderInvalidRows = () => {
    if (total <= processed.length && errored.length > 0) {
      return (
        <>
          <Box height="400px" size="sm" textAlign="left" mt={6}>
            <Text fontWeight="bold" fontColor="gray.700" textAlign="start" mb={2}>
              Payment links failed to be created:
            </Text>
            <Flex bgColor="red.50" height={10} fontSize="sm" fontWeight="bold">
              <Flex width="15%" alignItems="center" px={1}>
                Ref. ID
              </Flex>
              <Flex width="15%" alignItems="center" px={1}>
                Amount
              </Flex>
              <Flex width="40%" alignItems="center" px={1}>
                Errors
              </Flex>
            </Flex>
            <AutoSizer>
              {({ height, width }) => (
                <List height={height - 40} itemCount={errored.length} itemSize={40} width={width}>
                  {InvalidRow}
                </List>
              )}
            </AutoSizer>
          </Box>
        </>
      )
    }
    return null
  }

  /**
   * Renders action buttons
   */
  const renderActions = () => {
    if (total > processed.length) {
      return null
    }
    return (
      <Box mt={12} textAlign="right">
        <ButtonGroup variant="solid">
          <Button variant="ghost" onClick={close} colorScheme="primary">
            Close
          </Button>
          <Button onClick={downloadLogs} colorScheme="primary">
            Download Logs
          </Button>
        </ButtonGroup>
      </Box>
    )
  }

  /**
   * Presentation component for when creation is in progress and completed
   */
  return (
    <Flex flexDirection="column">
      <Box width="100%">
        {renderInfoBox()}
        {renderProgressBar()}
        {renderCreateSuccessful()}
        {renderCreateErrors()}
        {renderInvalidRows()}
        {renderActions()}
      </Box>
    </Flex>
  )
}

import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
})

// 2. Extend the theme to include custom colors, fonts, etc
const Theme = extendTheme({
  fonts: {
    heading: 'IBM Plex Sans',
    body: 'IBM Plex Sans',
  },
  colors: {
    primary: {
      50: '#f1f3f8',
      100: '#c6c8dd',
      200: '#b6bcda',
      300: '#8791c1',
      400: '#5765a9',
      500: '#283a90',
      600: '#202f77',
      700: '#1a265f',
      800: '#131b45',
      900: '#0c112b',
    },
    secondary: {
      50: '#f3f2f6',
      100: '#f5d4d4',
      200: '#edb2b2',
      300: '#e59393',
      400: '#666192',
      500: '#3b3574',
      600: '#302b5f',
      700: '#26224c',
      800: '#1c1937',
      900: '#110f22',
    },
    tertiary: {
      100: '#ECEBF0',
      200: '#C7C5D3',
      500: '#56527c',
    },
    neutral: {
      50: '#fbfafa',
      100: '#F0F1F2',
      200: '#6b727c',
      300: '#e0e2e5',
      400: '#c7cbd0',
      500: '#808894',
      600: '#969da7',
      700: '#545a61',
      800: '#40444a',
      900: '#26292C',
    },
    dark: {
      500: '#0a061e',
    },
    light: {
      500: '#f4f4f4',
    },
    pale: {
      500: '#eaffea',
    },
  },
  breakpoints,
})

export default Theme

import React from 'react'
import { Tr, Td, Tooltip, useDisclosure } from '@chakra-ui/react'

import { toDateTime } from 'utils/date'

// Components
import Actions from './Actions'
import LinkDetailsPanel from './LinkDetailsPanel'
import LinkPaymentIntentStatus from './LinkPaymentIntentStatus'
// import { CreatePaymentLinksProvider } from "contexts/createPaymentLinks.context"

const LinkRow = ({ row }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const link = row.original

  return (
    <Tr
      {...row.getRowProps()}
      fontSize="0.85em"
      _hover={{
        cursor: 'pointer',
      }}
    >
      {row.cells.map((cell) => {
        const { column, value } = cell
        switch (column.id) {
          case 'createdAt':
            return (
              <Td key={column.id} p={3} onClick={onOpen}>
                {toDateTime(value)}
              </Td>
            )

          case 'amount':
            return (
              <Td key={column.id} p={3} textAlign="right" onClick={onOpen}>
                {`$${(value / 100).toFixed(2)}`}
              </Td>
            )

          case 'paymentStatus':
            return (
              <Td key={column.id} p={3} onClick={onOpen}>
                <LinkPaymentIntentStatus paymentStatus={value} />
              </Td>
            )

          case 'email':
            return (
              <Td key={column.id} p={3} onClick={onOpen}>
                {value || '-'}
              </Td>
            )

          case 'lastSentTimestamp':
            return (
              <Td key={column.id} onClick={onOpen} p={3}>
                <Tooltip
                  label="This is the last time the email was sent by PaySG. The email could have been rejected by the recipient's mailbox provider."
                  py={2}
                  px={4}
                  isDisabled={!value}
                >
                  {value ? toDateTime(value) : 'N.A.'}
                </Tooltip>
              </Td>
            )

          case 'actions':
            return (
              <Td key={column.id} p={3}>
                <Actions link={link} />
              </Td>
            )

          default:
            return (
              <Td onClick={onOpen} key={column.id} p={3}>
                {cell.render('Cell')}
              </Td>
            )
        }
      })}
      <LinkDetailsPanel isOpen={isOpen} onOpen={onOpen} onClose={onClose} link={link} />
    </Tr>
  )
}

export default LinkRow

import React, { createRef } from 'react'
import {
  Box,
  Button,
  Container,
  Image,
  Heading,
  Flex,
  Text,
  VStack,
  HStack,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Link,
} from '@chakra-ui/react'
import mindefLogo from 'img/mindef_thumbnail.jpg'
import formSgLogo from 'img/formsg.svg'

const FakeForm = ({ isSubmitting, onSubmit }) => {
  const nricRef = createRef()

  // Generic Form constants
  const AMOUNT = 5600
  const DESCRIPTION = 'Army Tank'

  return (
    <>
      <Box bg="white">
        <Container maxW="container.md">
          <Flex width="100%" height="100%" justify="center" align="center">
            <Image src={mindefLogo} width="300px" />
          </Flex>
        </Container>
      </Box>
      <Box bg="blue.600">
        <Container maxW="container.md" height="400px">
          <Flex width="100%" height="100%" direction="column" justify="center" align="center">
            <Heading textAlign="center" size="4xl" color="white" fontWeight="400" lineHeight="1.1">
              Pay for Broken Weapon Fine
            </Heading>
            <Text color="white" mt="8">
              {' '}
              5 mins estimated time to complete
            </Text>
          </Flex>
        </Container>
      </Box>
      <Box bg="White">
        <Container maxW="container.md" py="24">
          <VStack spacing="24">
            <Box w="100%">
              <Heading textAlign="left" mb="10" color="blue.600">
                Your Particulars
              </Heading>
              <VStack spacing={12}>
                <FormControl>
                  <FormLabel fontSize="xl" fontWeight="bold">
                    1. NRIC
                  </FormLabel>
                  <Input size="lg" type="text" ref={nricRef} placeholder="Your NRIC" />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize="xl" fontWeight="bold">
                    2. Army Unit
                  </FormLabel>
                  <Input size="lg" type="text" placeholder="Agency Name" />
                </FormControl>
                <Box w="100%">
                  <FormControl>
                    <FormLabel fontSize="xl" fontWeight="bold">
                      3. Rank
                    </FormLabel>
                    <FormHelperText mb="5" fontSize="md">
                      If you do not find your agency bank account here, please fill up this form. If
                      not you can select the test bank account to test out the full flow.
                    </FormHelperText>
                  </FormControl>

                  <Select size="lg" placeholder="Select option">
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select>
                </Box>
              </VStack>
            </Box>

            <Box w="100%">
              <Heading textAlign="left" mb="10" color="blue.600">
                Payment Details
              </Heading>
              <VStack spacing={12}>
                <FormControl>
                  <FormLabel fontSize="xl" fontWeight="bold">
                    4. Product Name
                  </FormLabel>
                  <Text fontSize="lg">{DESCRIPTION}</Text>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize="xl" fontWeight="bold">
                    5. Amount
                  </FormLabel>
                  <Text fontSize="lg">${AMOUNT}</Text>
                </FormControl>
                <Text>You will be redirected to Pay.gov.sg to complete the payment process</Text>
              </VStack>
            </Box>
          </VStack>
          <Button
            w="100%"
            height="56px"
            colorScheme="blue"
            mt="12"
            fontSize="xl"
            textTransform="uppercase"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            onClick={() =>
              onSubmit({
                amount: AMOUNT,
                description: DESCRIPTION,
                referenceId: nricRef.current.value,
              })
            }
          >
            Submit
          </Button>
        </Container>
      </Box>
      <Box bg="white">
        <Container maxW="container.md">
          <Flex
            direction="column"
            width="100%"
            height="100%"
            justify="center"
            align="center"
            mb="240px"
          >
            <Box borderTop="2px" borderColor="gray.200" w="400px" mb="8" />
            <Text textTransform="uppercase" mb="10" color="gray.700">
              Powered By
            </Text>
            <Image src={formSgLogo} width="100px" />
            <HStack spacing="2em" mt="2em">
              <Link to="/" color="blue.600">
                <Text fontSize="sm">Privacy Policy</Text>
              </Link>
              <Link to="/" color="blue.600">
                <Text fontSize="sm">Terms of Use</Text>
              </Link>
            </HStack>
          </Flex>
        </Container>
      </Box>
    </>
  )
}

export default FakeForm

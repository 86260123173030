import React from 'react'
import {
  Button,
  HStack,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  useClipboard,
} from '@chakra-ui/react'
import {
  IoCopyOutline,
  // IoMailSharp,
  IoEllipsisVertical,
} from 'react-icons/io5'
import { formatPaymentLink } from 'utils/payment'

const Actions = ({ link }) => {
  // const [isSendingEmail, setIsSendingEmail] = useState(false)
  const { hasCopied, onCopy } = useClipboard(formatPaymentLink(link.id))

  /**
   * Opens payment link in stripe
   *
   * @todo remove the `test` in link in production
   */
  const openInStripe = () => {
    const stripeEnvironment = process.env.REACT_APP_ENV === 'production' ? '' : '/test'
    window.open(`https://dashboard.stripe.com${stripeEnvironment}/payments/${link.paymentIntentId}`)
  }

  /**
   * Copies the link to the clipboard
   */
  const copyLinkToClipboard = () => {
    onCopy()
  }

  return (
    <HStack>
      <Button
        colorScheme="primary"
        size="xs"
        fontWeight="normal"
        onClick={copyLinkToClipboard}
        leftIcon={<IoCopyOutline />}
        disabled={hasCopied}
      >
        {hasCopied ? 'Copied' : 'Copy Link'}
      </Button>
      {/*
    <Button
      colorScheme="primary"
      variant="link"
      size="sm"
      fontWeight="normal"
      isDisabled={!link.email}
      isLoading={isSendingEmail}
      loadingText="Sending..."
      onClick={sendPaymentEmail}
      leftIcon={<IoMailSharp />}
      mr={2}
    >
      Send Email
    </Button>  
    */}
      <Menu placement="bottom-end">
        <Tooltip label="More Actions" placement="top">
          <MenuButton
            as={IconButton}
            icon={<IoEllipsisVertical />}
            variant="outline"
            colorScheme="primary"
            size="xs"
          />
        </Tooltip>
        <MenuList>
          <MenuItem w="93%" mx="auto" borderRadius={5} onClick={openInStripe}>
            Open in Stripe
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  )
}

export default Actions

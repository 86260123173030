import React, { useEffect, useState } from 'react'
import { Alert, AlertIcon, Box, Button, Flex, Icon, Text } from '@chakra-ui/react'
import { VscFile, VscError } from 'react-icons/vsc'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { jsonToCsv, downloadCsv } from 'utils/csv'
import { useCreatePaymentLinks } from 'contexts/createPaymentLinks.context'

/**
 * Presentation component for when parsing is successful
 */
export default function BulkCreateLinksUploaderParseSuccess({ onCancel = () => {} }) {
  const [errorRows, setErrorRows] = useState([])
  const [successRows, setSuccessRows] = useState([])

  //  Use Custom Hook
  const { fileName, total, nextStep, parsed, reset } = useCreatePaymentLinks()

  /**
   * Filters success/errored rows
   */
  useEffect(() => {
    setSuccessRows(parsed.filter((row) => row.errors.length === 0))
    setErrorRows(parsed.filter((row) => row.errors.length > 0))
  }, [parsed])

  /**
   * onSuccess
   */
  const onSuccess = () => nextStep()

  /**
   * On Remove File
   */
  const onRemoveFile = () => {
    if (
      window.confirm('Please confirm that you want to restart the payment link creation process?')
    ) {
      reset()
    }
  }

  /**
   * Downloads the error logs
   */
  const downloadErrorLogs = () => {
    const csv = jsonToCsv(parsed)
    downloadCsv(csv, 'paysg_validation_failed.csv')
  }

  const ValidRowsAlert = () => {
    return (
      <Flex mb="3">
        <Alert status="info" borderRadius={5}>
          <AlertIcon />
          <Text>Please review the payment links before we proceed to create them.</Text>
        </Alert>
      </Flex>
    )
  }

  const InvalidRowsAlert = () => {
    return (
      <Flex mb="3">
        <Alert status="error" borderRadius={5}>
          <AlertIcon />
          <Text>
            There are errors in your template. Please correct them before re-uploading the template.
          </Text>
        </Alert>
      </Flex>
    )
  }

  /**
   * Renders file component
   */
  const FileComponent = () => {
    return (
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center" w="100%" mb={5}>
        <Flex flexDirection="row">
          <Flex mr="2">
            <Icon as={VscFile} h={10} w={10} color="gray.500" />
          </Flex>
          <Flex flexDirection="column" textAlign="left">
            <Text fontSize="0.85em" fontWeight="bold">
              {fileName}
            </Text>
            <Text fontSize="0.85em">{total} rows in total</Text>
          </Flex>
        </Flex>
        <Box float="right">
          <Button
            colorScheme="primary"
            float="right"
            leftIcon={<VscError />}
            lineHeight="1"
            fontSize="0.9rem"
            fontWeight="normal"
            p="0"
            size="sm"
            variant="link"
            onClick={onRemoveFile}
          >
            Remove&nbsp;File
          </Button>
        </Box>
      </Flex>
    )
  }

  /**
   * Renders a single valid row
   *
   * @returns
   */
  const ValidRow = ({ index, style }) => {
    const row = successRows[index]
    return (
      <Flex
        key={index}
        style={style}
        fontSize="sm"
        alignItems="center"
        bgColor={index % 2 ? 'gray.100' : ''}
      >
        <Flex width="20%" px={1}>
          {row.reference_id}
        </Flex>
        <Flex width="20%" px={1}>
          ${Number(row.amount).toFixed(2)}
        </Flex>
        <Flex width="60%" px={1} overflow="hidden">
          {row.description}
        </Flex>
      </Flex>
    )
  }

  /**
   * Renders only the valid rows
   *
   * @returns
   */
  const renderValidRows = () => {
    return (
      <>
        <Box height="400px" size="sm" textAlign="left">
          <Flex bgColor="blue.50" height={10} fontSize="sm" fontWeight="bold">
            <Flex width="20%" alignItems="center" px={1}>
              Ref. ID
            </Flex>
            <Flex width="20%" alignItems="center" px={1}>
              Amount
            </Flex>
            <Flex width="60%" alignItems="center" px={1}>
              Description
            </Flex>
          </Flex>
          <AutoSizer>
            {({ height, width }) => (
              <List height={height - 40} itemCount={successRows.length} itemSize={40} width={width}>
                {ValidRow}
              </List>
            )}
          </AutoSizer>
        </Box>
      </>
    )
  }

  /**
   * Renders a single invalid row
   *
   * @returns
   */
  const InvalidRow = ({ index, style }) => {
    const row = errorRows[index]
    return (
      <Flex
        key={index}
        style={style}
        fontSize="sm"
        alignItems="center"
        bgColor={index % 2 ? 'gray.100' : ''}
      >
        <Flex width="20%" px={1}>
          {row.reference_id}
        </Flex>
        <Flex width="20%" px={1}>
          ${Number(row.amount).toFixed(2)}
        </Flex>
        <Flex width="60%" px={1} overflow="hidden">
          {row.errors}
        </Flex>
      </Flex>
    )
  }

  /**
   * Renders only the invalid rows
   *
   * @returns
   */
  const renderInvalidRows = () => {
    return (
      <>
        <Box height="400px" size="sm" textAlign="left">
          <Flex bgColor="red.50" height={10} fontSize="sm" fontWeight="bold">
            <Flex width="20%" alignItems="center" px={1}>
              Ref. ID
            </Flex>
            <Flex width="20%" alignItems="center" px={1}>
              Amount
            </Flex>
            <Flex width="60%" alignItems="center" px={1}>
              Errors
            </Flex>
          </Flex>
          <AutoSizer>
            {({ height, width }) => (
              <List height={height - 40} itemCount={errorRows.length} itemSize={40} width={width}>
                {InvalidRow}
              </List>
            )}
          </AutoSizer>
        </Box>
      </>
    )
  }

  return (
    <>
      {errorRows.length === 0 && <ValidRowsAlert />}
      {errorRows.length > 0 && <InvalidRowsAlert />}
      <Flex
        borderWidth="1px"
        borderRadius="8px"
        borderColor="gray.200"
        flexDirection="column"
        p="3"
      >
        <Flex flexDirection="column" alignItems="flex-start">
          <Text fontWeight="bold" fontColor="gray.700" mb={2}>
            Displaying {errorRows.length > 0 && 'invalid'} rows for:
          </Text>
          <FileComponent />
        </Flex>
        {errorRows.length === 0 ? renderValidRows() : null}
        {errorRows.length > 0 ? renderInvalidRows() : null}
      </Flex>

      {/* Action Footer */}
      <Box mt="6" textAlign="right">
        <Button colorScheme="primary" variant="ghost" mr="4" onClick={onCancel}>
          Cancel
        </Button>
        {errorRows.length > 0 ? (
          <Button colorScheme="primary" onClick={downloadErrorLogs}>
            Download&nbsp;Logs
          </Button>
        ) : (
          <Button colorScheme="primary" onClick={onSuccess}>
            Create&nbsp;Links
          </Button>
        )}
      </Box>
    </>
  )
}

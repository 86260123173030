import React from 'react'
import { Box, Image, Container, Flex, Text, Link, HStack } from '@chakra-ui/react'

import ogpLogo from 'img/ogp-logo.svg'
import ogpLogoMark from 'img/ogp-logo-mark-white.svg'
import iconFacebook from 'img/icons/bxl-facebook.svg'
import iconLinkedIn from 'img/icons/bxl-linkedin.svg'

const OgpFooter = () => {
  const urlOgpWebsite = 'https://open.gov.sg'
  const urlOgpFacebook = 'https://www.facebook.com/opengovsg'
  const urlOgpLinkedIn = 'https://www.linkedin.com/company/open-government-products'

  return (
    <>
      <footer>
        <Box d="flex" alignItems="center" bg="#041026" w="100%" color="white" py="20">
          <Container maxW="96em">
            <Flex justify="space-between" align="flex-end">
              <Flex h="100%">
                <Flex direction="column">
                  <Text mb="4">Built by</Text>
                  <Image w="200px" src={ogpLogo} />
                </Flex>
              </Flex>
              <Flex h="100%" mt="5">
                <Flex direction="column" align="flex-end">
                  <HStack mb="2">
                    <Link href={urlOgpWebsite}>
                      <Image w="10" src={ogpLogoMark} />
                    </Link>
                    <Link href={urlOgpFacebook}>
                      <Image w="10" src={iconFacebook} />
                    </Link>
                    <Link href={urlOgpLinkedIn}>
                      <Image w="10" src={iconLinkedIn} />
                    </Link>
                  </HStack>
                  <Text>
                    &copy;2021 Open Government Products, Government Technology Agency of Singapore
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Container>
        </Box>
      </footer>
    </>
  )
}

export default OgpFooter

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Storage from '../services/Storage'

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        Storage.isUserSaved() && restricted ? <Redirect to="/services" /> : <Component {...props} />
      }
    />
  )
}

export default PublicRoute

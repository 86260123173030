import React from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react'
import { CreatePaymentLinksProvider } from 'contexts/createPaymentLinks.context'
// eslint-disable-next-line import/no-cycle
import { BulkCreateLinksUploader } from './index'
import 'styles/components/Modal.scss'
import ErrorToast from '../ErrorToast'

export default function BulkCreateLinksModal({ service, refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const checkForStripeAccount = () => {
    if (process.env.REACT_APP_ENV === 'production' && !(service && service.stripeAccountId)) {
      ErrorToast()
    } else {
      onOpen()
    }
  }

  return (
    <>
      <Button colorScheme="primary" variant="outline" onClick={checkForStripeAccount}>
        Bulk&nbsp;Create
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent maxWidth="90vw" width="850px" p="3" pb="6">
          <ModalHeader>Bulk Create Payment Links</ModalHeader>
          <ModalBody>
            <CreatePaymentLinksProvider>
              <BulkCreateLinksUploader refetch={refetch} onCancel={onClose} />
            </CreatePaymentLinksProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
